import axios from 'axios';
import { SavedChat, StreamResponse, ChatConfig, ApiResponse } from '../../types/chat';

// Add this interface
interface ChatResponse {
  message: {
    content: string;
    role: string;
  };
  status: number;
}

const API_BASE_URL = 'https://am-test.brainchain.cloud/api/v1';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: false,
  timeout: 30000,
  timeoutErrorMessage: 'Request took too long to respond'
});

// Add these exports at the top
export const generateTempId = () => {
  const random = Math.random().toString(36).substring(2, 15);
  const timestamp = Date.now();
  return `temp_${timestamp}_${random}`;
};

// Add this interface at the top of the file
interface ConversationIndex {
  activeIds: string[];
  archivedIds: string[];
  lastUpdated: string;
}

// Get conversation index
const getConversationIndex = (agentId: string): ConversationIndex => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY.chatList(agentId)) || JSON.stringify({
      activeIds: [],
      archivedIds: [],
      lastUpdated: new Date().toISOString()
    }));
  } catch (error) {
    console.error('Failed to get conversation index:', error);
    return {
      activeIds: [],
      archivedIds: [],
      lastUpdated: new Date().toISOString()
    };
  }
};

// Save conversation index
const saveConversationIndex = (agentId: string, index: ConversationIndex) => {
  try {
    localStorage.setItem(STORAGE_KEY.chatList(agentId), JSON.stringify({
      ...index,
      lastUpdated: new Date().toISOString()
    }));
  } catch (error) {
    console.error('Failed to save conversation index:', error);
  }
};

// Get active conversations
export const getActiveConversations = (agentId: string): SavedChat[] => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY.chatList(agentId)) || '[]');
  } catch (error) {
    console.error('Failed to get active conversations:', error);
    return [];
  }
};

// Get archived conversations
export const getArchivedConversations = (agentId: string): SavedChat[] => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY.chatList(agentId)) || '[]');
  } catch (error) {
    console.error('Failed to get archived conversations:', error);
    return [];
  }
};

// Save conversation with proper indexing
export const saveConversation = async (agentId: string, conversation: SavedChat): Promise<void> => {
  try {
    // Save full chat data
    await saveChatData(agentId, conversation);

    // Update chat list
    const chatList = await loadChatList(agentId);
    const updatedList = [conversation, ...chatList.filter((c: SavedChat) => c.id !== conversation.id)];
    saveChatMetadata(agentId, updatedList);

  } catch (error) {
    console.error('Failed to save conversation:', error);
  }
};

// Add these exports at the top
export const getAllConversations = async (agentId: string): Promise<SavedChat[]> => {
  try {
    const chatList = await loadChatList(agentId);
    const chats: SavedChat[] = [];

    for (const metadata of chatList) {
      const chatData = loadChatData(agentId, metadata.id);
      if (chatData) {
        chats.push(chatData);
      }
    }

    // Sort by updated_at, most recent first
    return chats.sort((a: SavedChat, b: SavedChat) => 
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    );

  } catch (error) {
    console.error('Failed to get all conversations:', error);
    return [];
  }
};

// Update API configuration
const API_CONFIG = {
  DEFAULT: {
    model: 'gpt-4o',
    temperature: 0.1,
    top_p: 0.25,
    max_tokens: 1024,
    n: 1,
    stream: false as const
  }
} as const;

// Update the conversation ID generation to ensure uniqueness
const generateConversationId = () => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2, 15);
  return `conv_${timestamp}_${random}`;
};

// Add near top with other interfaces
interface DraftChat extends Omit<SavedChat, 'id'> {
  id: string;
  isDraft: boolean;
}

// Update startConversation to not save automatically
export const startConversation = async (agentId: string): Promise<DraftChat> => {
  const conversationId = generateConversationId();
  
  return {
    id: conversationId,
    title: 'New Chat',
    agent_id: agentId,
    messages: [],
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    isDraft: true // Mark as draft
  };
};

// Add these new utility functions at the top of the file
const validateStorageKey = (key: string): boolean => {
  try {
    const value = localStorage.getItem(key);
    return value !== null && value !== undefined;
  } catch (error) {
    console.error('Storage key validation failed:', error);
    return false;
  }
};

// Update the createStorageBackup function to handle async loadChatList
const createStorageBackup = async (agentId: string) => {
  try {
    const chatList = await loadChatList(agentId);
    const backup = {
      timestamp: new Date().toISOString(),
      chatList,
      chats: {} as Record<string, SavedChat>
    };

    // Backup individual chat data
    for (const chat of chatList) {
      const chatData = loadChatData(agentId, chat.id);
      if (chatData) {
        backup.chats[chat.id] = chatData;
      }
    }

    localStorage.setItem(`backup:${agentId}:${Date.now()}`, JSON.stringify(backup));
    return true;
  } catch (error) {
    console.error('Failed to create backup:', error);
    return false;
  }
};

// Add this recovery function
const recoverChats = async (agentId: string): Promise<boolean> => {
  try {
    const backupKeys = Object.keys(localStorage)
      .filter(key => key.startsWith(`backup:${agentId}:`))
      .sort()
      .reverse();

    if (backupKeys.length === 0) return false;

    const latestBackup = localStorage.getItem(backupKeys[0]);
    if (!latestBackup) return false;

    const backup = JSON.parse(latestBackup);
    
    // Restore chat list
    saveChatMetadata(agentId, backup.chatList);
    
    // Fix the type issue with Object.values
    Object.values(backup.chats).forEach((chat: any) => {
      if (chat && chat.id) {
        localStorage.setItem(
          STORAGE_KEY.chatData(agentId, chat.id),
          JSON.stringify(chat)
        );
      }
    });

    return true;
  } catch (error) {
    console.error('Chat recovery failed:', error);
    return false;
  }
};

// Add this new function for atomic save operations
const saveConversationAtomic = async (agentId: string, chat: SavedChat): Promise<void> => {
  try {
    console.log('Starting atomic save operation:', {
      agentId,
      chatId: chat.id,
      timestamp: new Date().toISOString()
    });

    // Create backup first
    await createStorageBackup(agentId);

    // Save both chat data and metadata in one operation
    const chatKey = STORAGE_KEY.chatData(agentId, chat.id);
    const metadataKey = STORAGE_KEY.chatList(agentId);

    // Get existing chats for metadata update
    const existingChats = await loadChatList(agentId);
    const updatedChats = [
      chat,
      ...existingChats.filter(c => c.id !== chat.id)
    ];

    // Save both atomically
    localStorage.setItem(chatKey, JSON.stringify(chat));
    localStorage.setItem(metadataKey, JSON.stringify(updatedChats));

    // Verify save was successful
    const savedChat = localStorage.getItem(chatKey);
    const savedMetadata = localStorage.getItem(metadataKey);

    if (!savedChat || !savedMetadata) {
      throw new Error('Atomic save verification failed');
    }

    console.log('Atomic save completed successfully');
  } catch (error) {
    console.error('Atomic save failed:', error);
    // Attempt recovery
    const recovered = await recoverChats(agentId);
    if (!recovered) {
      throw error;
    }
  }
};

// Revert back to original saveChatData but keep the logging
export const saveChatData = async (agentId: string, chat: SavedChat | DraftChat): Promise<void> => {
  try {
    console.log('Saving chat data:', {
      agentId,
      chatId: chat.id,
      messageCount: chat.messages.length,
      timestamp: new Date().toISOString()
    });

    // Create backup before making changes
    await createStorageBackup(agentId);

    if ('isDraft' in chat && chat.isDraft && chat.messages.length === 0) {
      return;
    }

    const key = STORAGE_KEY.chatData(agentId, chat.id);
    const { isDraft, ...chatToSave } = chat as DraftChat;
    
    localStorage.setItem(key, JSON.stringify({
      ...chatToSave,
      updated_at: new Date().toISOString()
    }));

    // Validate storage operation
    if (!validateStorageKey(key)) {
      throw new Error('Storage validation failed');
    }

    console.log('Chat data saved successfully');
  } catch (error) {
    console.error('Failed to save chat data:', error);
    // Attempt recovery if save fails
    const recovered = await recoverChats(agentId);
    if (!recovered) {
      throw error;
    }
  }
};

// Update saveChatMetadata to preserve timestamps
export const saveChatMetadata = (agentId: string, chats: (SavedChat | DraftChat)[]): void => {
  try {
    const nonDraftChats = chats.filter(chat => 
      !('isDraft' in chat) || 
      (!chat.isDraft || chat.messages.length > 0)
    );

    const metadata = nonDraftChats.map(chat => ({
      id: chat.id,
      title: chat.title,
      created_at: chat.created_at,
      updated_at: chat.updated_at,
      agent_id: chat.agent_id
    }));
    
    localStorage.setItem(STORAGE_KEY.chatList(agentId), JSON.stringify(metadata));
  } catch (error) {
    console.error('Failed to save chat metadata:', error);
  }
};

// Add these constants at the top with other STORAGE_KEYs
const STORAGE_KEY = {
  chatList: (agentId: string): string => `chats:${agentId}:list`,
  chatData: (agentId: string, chatId: string): string => `chats:${agentId}:${chatId}`,
  activeChat: (agentId: string): string => `chats:${agentId}:active`,
  settings: 'app:settings' as const
} as const;

export const DEFAULT_SETTINGS = {
  dataRetentionPeriod: 30  // 30 days default
} as const;

// Add this function to ensure settings exist
export const ensureSettings = () => {
  try {
    const settings = localStorage.getItem(STORAGE_KEY.settings);
    if (!settings) {
      localStorage.setItem(STORAGE_KEY.settings, JSON.stringify(DEFAULT_SETTINGS));
      return DEFAULT_SETTINGS;
    }
    const parsedSettings = JSON.parse(settings);
    // Ensure dataRetentionPeriod exists and is valid
    if (!parsedSettings.dataRetentionPeriod || 
        parsedSettings.dataRetentionPeriod < 1 || 
        parsedSettings.dataRetentionPeriod > 365) {
      parsedSettings.dataRetentionPeriod = DEFAULT_SETTINGS.dataRetentionPeriod;
      localStorage.setItem(STORAGE_KEY.settings, JSON.stringify(parsedSettings));
    }
    return parsedSettings;
  } catch (error) {
    console.error('Failed to ensure settings:', error);
    localStorage.setItem(STORAGE_KEY.settings, JSON.stringify(DEFAULT_SETTINGS));
    return DEFAULT_SETTINGS;
  }
};

// Update the cleanup function to use proper retention logic
export const cleanupConversations = async (agentId: string): Promise<SavedChat[]> => {
  try {
    const chatList = await loadChatList(agentId);
    const now = Date.now();
    const settings = ensureSettings();
    const retentionPeriod = settings.dataRetentionPeriod;
    
    if (!retentionPeriod || !agentId) {
      return chatList;
    }

    // Calculate cutoff time based on retention period
    const cutoffTime = now - (retentionPeriod * 24 * 60 * 60 * 1000);
    
    // Keep chats that are either:
    // 1. Within retention period OR
    // 2. Have been active in the last 30 minutes
    const cleanedChats = chatList.filter((chat: SavedChat) => {
      const lastActivity = new Date(chat.updated_at).getTime();
      const createdTime = new Date(chat.created_at).getTime();
      const isRecent = lastActivity > (now - (30 * 60 * 1000)); // Active in last 30 minutes
      const isWithinRetention = createdTime > cutoffTime; // Within retention period
      
      return isRecent || isWithinRetention;
    });

    // Sort by most recent first
    cleanedChats.sort((a: SavedChat, b: SavedChat) => 
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    );

    // Remove deleted chat data from storage
    const deletedChats = chatList.filter(
      (chat: SavedChat) => !cleanedChats.find((c: SavedChat) => c.id === chat.id)
    );
    
    deletedChats.forEach((chat: SavedChat) => {
      console.log(`Removing chat older than ${retentionPeriod} days:`, chat.id);
      localStorage.removeItem(STORAGE_KEY.chatData(agentId, chat.id));
    });

    // Update metadata
    saveChatMetadata(agentId, cleanedChats);

    return cleanedChats;
  } catch (error) {
    console.error('Failed to cleanup conversations:', error);
    return loadChatList(agentId);
  }
};

// Export these functions that ChatInterface.tsx needs
export const saveTempConversation = async (agentId: string, conversation: SavedChat) => {
  try {
    // Save the individual chat data
    const key = STORAGE_KEY.chatData(agentId, conversation.id);
    localStorage.setItem(key, JSON.stringify(conversation));
    
    // Update conversations list
    const conversationsList = await loadChatList(agentId);
    const updatedConversations = [
      conversation,
      ...conversationsList.filter((c: SavedChat) => c.id !== conversation.id)
    ];
    
    // Save the updated list
    saveChatMetadata(agentId, updatedConversations);
    return updatedConversations;
  } catch (error) {
    console.error('Failed to save temp conversation:', error);
    return [];
  }
};

export const savePermanentConversation = async (agentId: string, conversation: SavedChat) => {
  try {
    // Save the individual chat data
    const key = STORAGE_KEY.chatData(agentId, conversation.id);
    localStorage.setItem(key, JSON.stringify(conversation));
    
    // Update conversations list
    const conversationsList = await loadChatList(agentId);
    const updatedConversations = [
      conversation,
      ...conversationsList.filter((c: SavedChat) => c.id !== conversation.id)
    ];
    
    // Save the updated list
    saveChatMetadata(agentId, updatedConversations);
    return updatedConversations;
  } catch (error) {
    console.error('Failed to save permanent conversation:', error);
    return [];
  }
};

// Export configurations
export { API_CONFIG };

// Add these helper functions
export const loadChatList = async (agentId: string): Promise<SavedChat[]> => {
  try {
    const key = STORAGE_KEY.chatList(agentId);
    const data = localStorage.getItem(key);
    
    if (!data) {
      const recovered = await recoverChats(agentId);
      if (recovered) {
        return loadChatList(agentId);
      }
      return [];
    }

    const chatList: SavedChat[] = JSON.parse(data);
    
    // Fix the type for the filter callback
    const validChats = chatList.filter((chat: SavedChat) => {
      const chatData = loadChatData(agentId, chat.id);
      return chatData !== null;
    });

    if (validChats.length !== chatList.length) {
      saveChatMetadata(agentId, validChats);
    }

    return validChats;
  } catch (error) {
    console.error('Failed to load chat list:', error);
    const recovered = await recoverChats(agentId);
    if (recovered) {
      return loadChatList(agentId);
    }
    return [];
  }
};

export const loadChatData = (agentId: string, chatId: string): SavedChat | null => {
  try {
    const data = localStorage.getItem(STORAGE_KEY.chatData(agentId, chatId));
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('Failed to load chat data:', error);
    return null;
  }
};

export const getActiveChat = (agentId: string): string | null => {
  try {
    return localStorage.getItem(STORAGE_KEY.activeChat(agentId));
  } catch (error) {
    console.error('Failed to get active chat:', error);
    return null;
  }
};

// Fix generateUniqueId reference and re-add chatApi object
const generateUniqueId = (prefix: string) => {
  const timestamp = Date.now();
  const random = Math.random().toString(36).substring(2, 15);
  return `${prefix}_${timestamp}_${random}`;
};

// Add retry logic for API calls
const fetchWithRetry = async (url: string, options: RequestInit, maxRetries = 3): Promise<Response> => {
  let lastError: Error | null = null;
  
  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      const response = await fetch(url, {
        ...options,
        // Add additional headers to help prevent chunking issues
        headers: {
          ...options.headers,
          'Connection': 'keep-alive',
          'Keep-Alive': 'timeout=60'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      // Ensure we can read the entire response
      const data = await response.json();
      return new Response(JSON.stringify(data), {
        status: response.status,
        headers: response.headers
      });
    } catch (error: unknown) {
      console.warn(`Attempt ${attempt + 1} failed:`, error);
      lastError = error instanceof Error ? error : new Error(String(error));
      
      // Only retry on network errors or incomplete responses
      const errorString = String(error);
      if (!errorString.includes('ERR_INCOMPLETE_CHUNKED_ENCODING') && 
          !errorString.includes('Failed to fetch')) {
        throw lastError;
      }
      
      // Wait before retrying
      await new Promise(resolve => setTimeout(resolve, 1000 * (attempt + 1)));
    }
  }
  
  throw lastError || new Error('Failed to fetch after retries');
};

// Re-add chatApi object with the core methods
export const chatApi = {
  async startConversation(agentId: string): Promise<DraftChat> {
    try {
      const conversationId = generateConversationId();
      const newChat: DraftChat = {
        id: conversationId,
        title: 'New Chat',
        agent_id: agentId,
        messages: [],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        isDraft: true  // Mark as draft initially
      };

      // Store in memory or temporary storage if needed
      return newChat;
    } catch (error) {
      console.error('Failed to start conversation:', error);
      throw error;
    }
  },

  async getConversation(agentId: string, conversationId: string): Promise<SavedChat> {
    try {
      // Try to get from local storage first
      const chatData = loadChatData(agentId, conversationId);
      if (chatData) {
        return chatData;
      }

      // If not in storage, throw error
      throw new Error('Conversation not found');
    } catch (error) {
      console.error('Failed to get conversation:', error);
      throw error;
    }
  },

  async getConversations(agentId: string): Promise<SavedChat[]> {
    try {
      // First try to get from localStorage
      const conversations = await getAllConversations(agentId);
      
      if (conversations.length > 0) {
        return conversations;
      }

      // If no local conversations, create a new one
      const newChat = await this.startConversation(agentId);
      return [newChat];
    } catch (error) {
      console.error('Failed to get conversations:', error);
      return [];
    }
  },

  async deleteConversation(agentId: string, conversationId: string): Promise<void> {
    try {
      // Get current conversations using correct storage key
      const conversations = await loadChatList(agentId);
      
      // Filter out the deleted conversation
      const updatedConversations = conversations.filter(c => c.id !== conversationId);
      
      // Remove chat data
      localStorage.removeItem(STORAGE_KEY.chatData(agentId, conversationId));
      
      // Update chat list using correct storage key
      saveChatMetadata(agentId, updatedConversations);

      // If this was the last conversation, create a new one
      if (updatedConversations.length === 0) {
        await this.startConversation(agentId);
      }
    } catch (error) {
      console.error('Failed to delete conversation:', error);
      throw error;
    }
  },

  async runCommand(agentId: string, content: string): Promise<Response> {
    const response = await fetch(`${API_BASE_URL}/agent/${agentId}/chat/completions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        messages: [{
          role: "user",
          content: content,
          type: "COMMAND"
        }],
        ...API_CONFIG.DEFAULT
      })
    });

    if (!response.ok) {
      throw new Error(`Command failed: ${response.status} ${response.statusText}`);
    }

    return response;
  },

  async processTask(agentId: string, content: string): Promise<Response> {
    const response = await fetch(`${API_BASE_URL}/agent/${agentId}/chat/completions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        messages: [{
          role: "user",
          content: content,
          type: "TASK"
        }],
        ...API_CONFIG.DEFAULT
      })
    });

    if (!response.ok) {
      throw new Error(`Task processing failed: ${response.status} ${response.statusText}`);
    }

    return response;
  },

  async processGoal(agentId: string, content: string): Promise<Response> {
    const response = await fetch(`${API_BASE_URL}/agent/${agentId}/chat/completions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        messages: [{
          role: "user",
          content: content,
          type: "GOAL"
        }],
        ...API_CONFIG.DEFAULT
      })
    });

    if (!response.ok) {
      throw new Error(`Goal processing failed: ${response.status} ${response.statusText}`);
    }

    return response;
  },

  async sendMessage(request: any): Promise<ChatResponse> {
    const now = new Date().toISOString();
    console.log('Sending message:', { agentId: request.agent_id, timestamp: now });
    
    try {
      const response = await fetchWithRetry(
        `${API_BASE_URL}/agent/${request.agent_id}/chat/completions`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify({
            messages: [{
              role: "user",
              content: request.messages[request.messages.length - 1].content
            }],
            ...API_CONFIG.DEFAULT
          })
        }
      );

      const data = await response.json();
      console.log('Received response:', { status: response.status, hasContent: !!data.message });

      return {
        message: {
          content: data.message?.content || data.message || '',
          role: 'assistant'
        },
        status: response.status
      };
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  },

  async saveChat(chat: DraftChat | SavedChat): Promise<SavedChat> {
    try {
      if (chat.messages.length > 0 || !('isDraft' in chat)) {
        const now = new Date().toISOString();  // Create single timestamp
        const savedChat: SavedChat = {
          id: chat.id,
          title: chat.title,
          agent_id: chat.agent_id,
          messages: chat.messages,
          created_at: chat.created_at,
          updated_at: now  // Use the same timestamp
        };

        // Save individual chat data
        localStorage.setItem(
          STORAGE_KEY.chatData(chat.agent_id, chat.id),
          JSON.stringify(savedChat)
        );

        // Update chat list with same timestamp
        const chatList = await loadChatList(chat.agent_id);
        const updatedList = [savedChat, ...chatList.filter(c => c.id !== chat.id)];
        saveChatMetadata(chat.agent_id, updatedList);

        return savedChat;
      }
      throw new Error('Cannot save empty chat');
    } catch (error) {
      console.error('Failed to save chat:', error);
      throw error;
    }
  }
};

// Update the existing clearAllData function to use the new clear function
export const clearAllData = async (): Promise<boolean> => {
  try {
    const allKeys = Object.keys(localStorage);
    
    allKeys
      .filter(key => !key.startsWith('auth:') && !key.includes('auth0'))
      .forEach(key => {
        console.log('Removing:', key);
        localStorage.removeItem(key);
      });

    return true;
  } catch (error) {
    console.error('Failed to clear data:', error);
    return false;
  }
};
