import React from "react";
import { 
  FileText, 
  FileImage, 
  FileArchive, 
  FileSpreadsheet, 
  File as FileIcon, 
  Eye, 
  Edit, 
  Trash2, 
  RefreshCw, 
  RefreshCcw 
} from 'lucide-react';
import { Document } from '../../types/document';
import { formatFileSize, formatTimeAgo } from '../../utils/formatters';

interface DocumentListProps {
  documents: Document[];
  onView: (doc: Document) => void;
  onUpdate: (id: string, metadata: any) => void;
  onDelete: (id: string) => void;
  onRetry: (id: string) => void;
}

export const DocumentList: React.FC<DocumentListProps> = ({ 
  documents, 
  onView, 
  onUpdate, 
  onDelete, 
  onRetry 
}) => {
  const getFileIcon = (type: string | undefined) => {
    switch (type?.toLowerCase()) {
      case "pdf":
        return <FileText className="h-5 w-5 text-[#004DB5]" />;
      case "docx":
      case "doc":
        return <FileText className="h-5 w-5 text-[#7D7D7D]" />;
      case "xlsx":
      case "xls":
      case "csv":
        return <FileSpreadsheet className="h-5 w-5 text-[#004DB5]" />;
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
        return <FileImage className="h-5 w-5 text-[#7D7D7D]" />;
      case "zip":
      case "rar":
        return <FileArchive className="h-5 w-5 text-[#004DB5]" />;
      default:
        return <FileIcon className="h-5 w-5 text-gray-500" />;
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Uploaded By
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {documents.map((doc) => (
            <tr key={doc.id}>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  {getFileIcon(doc.metadata.type)}
                  <div className="ml-2 flex flex-col">
                    <span className="truncate max-w-xs" title={doc.title}>
                      {doc.title}
                    </span>
                    <div className="flex items-center space-x-2">
                      {doc.metadata.status && (
                        <span className={`text-xs px-2 py-0.5 rounded-full inline-flex items-center ${
                          doc.metadata.status === 'processing' ? 'bg-yellow-100 text-yellow-800' :
                          doc.metadata.status === 'error' ? 'bg-red-100 text-red-800' :
                          'bg-green-100 text-green-800'
                        }`}>
                          {doc.metadata.status === 'processing' && '⏳ Processing'}
                          {doc.metadata.status === 'error' && '❌ Failed'}
                          {doc.metadata.status === 'complete' && '✓ Complete'}
                        </span>
                      )}
                      <span className="text-xs text-gray-500">
                        {formatTimeAgo(doc.metadata.created_at || '')}
                      </span>
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {doc.metadata.uploaded_by}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {doc.metadata.created_at ? 
                  new Date(doc.metadata.created_at).toLocaleDateString() : 
                  'Unknown date'
                }
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center space-x-2">
                  <div className="relative group">
                    <button
                      onClick={() => onView(doc)}
                      className="text-[#004DB5] hover:text-blue-700 p-1 rounded hover:bg-blue-50"
                      aria-label="View document"
                    >
                      <Eye className="h-5 w-5" />
                    </button>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
                      <div className="relative">
                        View document details
                        <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full w-2 h-2 bg-gray-900 rotate-45"></div>
                      </div>
                    </div>
                  </div>

                  {doc.metadata.status === 'error' && (
                    <div className="relative group">
                      <button
                        onClick={() => onRetry(doc.id)}
                        className="text-yellow-600 hover:text-yellow-700 p-1 rounded hover:bg-yellow-50"
                        aria-label="Retry processing"
                      >
                        <RefreshCw className="h-5 w-5" />
                      </button>
                      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
                        <div className="relative">
                          Retry document processing
                          <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full w-2 h-2 bg-gray-900 rotate-45"></div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="relative group">
                    <button
                      onClick={() => onUpdate(doc.id, { title: `${doc.title} (Edited)` })}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <div className="absolute top-full left-0 mt-2 max-w-xs bg-gray-800 text-white text-xs rounded p-2 invisible group-hover:visible z-50 shadow-lg">
                      <div className="whitespace-normal">
                        Edit document metadata
                      </div>
                    </div>
                  </div>
                  <div className="relative group">
                    <button
                      onClick={() => onDelete(doc.id)}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                    <div className="absolute top-full left-0 mt-2 max-w-xs bg-gray-800 text-white text-xs rounded p-2 invisible group-hover:visible z-50 shadow-lg">
                      <div className="whitespace-normal">
                        Permanently delete this document
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
