import React, { useState, useEffect, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Menu,
  X,
  MessageSquare,
  Settings as SettingsIcon,
  PieChart,
  Network,
  List,
  Wrench,
  TrendingUp,
  BarChart2,
  Sliders,
  FileText,
  Bell,
} from "lucide-react";
import ChatInterface from "../chat/ChatInterface";
import AgentPersonaSelector from "../agent/AgentPersonaSelector";
import KnowledgeGraph from "../knowledge/KnowledgeGraph";
import TaskDashboard from "../TaskDashboard";
import ToolManagement from "../ToolManagement";
import SelfImprovementTracker from "../SelfImprovementTracker";
import EvaluationDashboard from "../EvaluationDashboard";
import AgentOverview from "../AgentOverview";
import AgentConfigurationInterface from "../agent/AgentConfigurationInterface";
import DocumentLibrary from "../DocumentLibrary/DocumentLibrary";
import SettingsComponent from "../Settings";
import { AgentProvider } from "../../context/AgentContext";
import Notifications from "../Notifications";
import Logo from "../../assets/logo-brainchain.png";

const DashboardLayout: React.FC = () => {
  const { user, logout } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [agentSelectorOpen, setAgentSelectorOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [activeTab, setActiveTab] = useState<
    | "overview"
    | "chat"
    | "knowledge"
    | "tasks"
    | "tools"
    | "improvement"
    | "evaluation"
    | "settings"
    | "agentConfig"
    | "documents"
  >("overview");
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const sidebarRef = useRef<HTMLDivElement>(null);
  const notificationsRef = useRef<HTMLDivElement>(null);
  const bellIconRef = useRef<HTMLButtonElement>(null);
  const agentSelectorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setSidebarOpen(false);
      }
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target as Node)
      ) {
        setNotificationsOpen(false);
      }
      if (
        agentSelectorRef.current &&
        !agentSelectorRef.current.contains(event.target as Node)
      ) {
        setAgentSelectorOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const toggleNotifications = () => {
    if (!notificationsOpen) {
      const bellIcon = bellIconRef.current;
      if (bellIcon) {
        const rect = bellIcon.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let left = rect.left;
        let top = rect.bottom + window.scrollY;

        // Adjust horizontal position if it would overflow
        if (left + 320 > viewportWidth) {
          left = viewportWidth - 320;
        }

        // Adjust vertical position if it would overflow
        if (top + 400 > viewportHeight) {
          top = rect.top - 400 + window.scrollY;
        }

        setDropdownPosition({ top, left });
      }
    }
    setNotificationsOpen(!notificationsOpen);
  };

  const toggleAgentSelector = () => setAgentSelectorOpen(!agentSelectorOpen);

  return (
    <AgentProvider>
      <div className="flex h-screen bg-gray-100">
        {/* Sidebar */}
        <div
          ref={sidebarRef}
          className={`bg-white w-64 shadow-lg fixed h-full flex flex-col transition-transform duration-300 ease-in-out z-30 ${
            sidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } lg:translate-x-0`}
        >
          {/* Unified Sidebar & Top Nav */}
          <div className="bg-[#043276] flex items-center justify-between h-16 px-4">
            <img src={Logo} alt="Brainchain Logo" className="h-10" />
            <button onClick={toggleSidebar} className="lg:hidden text-white">
              <X size={24} />
            </button>
          </div>

          {/* Navigation Links - Updated order and styling */}
          <nav className="flex-1 px-2 py-4">
            <button
              onClick={() => {
                setActiveTab("overview");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "overview"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <PieChart className="mr-3" size={20} />
              Overview
            </button>
            <button
              onClick={() => {
                setActiveTab("chat");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "chat"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <MessageSquare className="mr-3" size={20} />
              Chat
            </button>
            <button
              onClick={() => {
                setActiveTab("knowledge");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "knowledge"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <Network className="mr-3" size={20} />
              Knowledge Graph
            </button>
            <button
              onClick={() => {
                setActiveTab("documents");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "documents"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <FileText className="mr-3" size={20} />
              Document Library
            </button>
            <button
              onClick={() => {
                setActiveTab("tools");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "tools"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-400 hover:bg-gray-100"
              }`}
            >
              <Wrench className="mr-3" size={20} />
              Tool Management
            </button>
            <button
              onClick={() => {
                setActiveTab("tasks");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "tasks"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-400 hover:bg-gray-100"
              }`}
            >
              <List className="mr-3" size={20} />
              Tasks
            </button>
            <button
              onClick={() => {
                setActiveTab("improvement");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "improvement"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-400 hover:bg-gray-100"
              }`}
            >
              <TrendingUp className="mr-3" size={20} />
              Self Improvement
            </button>
            <button
              onClick={() => {
                setActiveTab("evaluation");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "evaluation"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-400 hover:bg-gray-100"
              }`}
            >
              <BarChart2 className="mr-3" size={20} />
              Evaluation
            </button>
            <button
              onClick={() => {
                setActiveTab("agentConfig");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-4 py-2 text-left ${
                activeTab === "agentConfig"
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-400 hover:bg-gray-100"
              }`}
            >
              <Sliders className="mr-3" size={20} />
              Agent Configuration
            </button>
          </nav>

          {/* Bottom Section of Sidebar */}
          <div className="mt-auto">
            {/* Settings Button - Without top border */}
            <button
              onClick={() => {
                setActiveTab("settings");
                if (window.innerWidth < 1024) {
                  setSidebarOpen(false);
                }
              }}
              className={`flex items-center w-full px-6 py-3 text-left hover:bg-gray-50 transition-colors ${
                activeTab === "settings"
                  ? "bg-blue-50 text-[#004DB5]"
                  : "text-gray-700"
              }`}
            >
              <SettingsIcon className="mr-3" size={20} />
              Settings
            </button>

            {/* User Profile Section - Keep this border */}
            <div className="px-6 py-4 border-t border-gray-200">
              <div className="flex items-center space-x-3">
                {/* User Avatar - First letter of name */}
                <div className="w-8 h-8 rounded-full bg-[#004DB5] text-white flex items-center justify-center font-medium">
                  {(user?.given_name || user?.name || 'U')[0].toUpperCase()}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="text-sm font-medium text-gray-900 truncate">
                    {user?.given_name || user?.name || 'User'}
                  </div>
                  <div className="text-xs text-gray-500 truncate">
                    {user?.email}
                  </div>
                </div>
              </div>
              <button
                onClick={() => logout()}
                className="mt-3 w-full bg-gray-100 text-gray-600 px-3 py-2 text-sm rounded-md hover:bg-gray-200 transition-colors flex items-center justify-center font-medium"
              >
                Log out
              </button>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col lg:ml-64 h-screen overflow-hidden">
          <header className="bg-[#043276] shadow-sm h-16 flex items-center justify-between px-4 lg:px-6 fixed top-0 right-0 left-0 lg:left-64 z-40">
            <div className="flex items-center">
              <button
                onClick={toggleSidebar}
                className="lg:hidden text-white mr-4"
              >
                {sidebarOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
              <h2 className="text-xl font-semibold text-white hidden lg:block">
                Autobot Agentic System
              </h2>
            </div>
            <div className="flex items-center space-x-4 text-white">
              <div ref={notificationsRef} className="relative">
                <button
                  ref={bellIconRef}
                  onClick={toggleNotifications}
                  className="focus:outline-none"
                >
                  <Bell size={24} />
                  {unreadNotifications > 0 && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                      {unreadNotifications}
                    </span>
                  )}
                </button>
                {notificationsOpen &&
                  createPortal(
                    <div
                      style={{
                        position: "absolute",
                        top: `${dropdownPosition.top}px`,
                        left: `${dropdownPosition.left}px`,
                        zIndex: 50,
                      }}
                    >
                      <Notifications
                        onClose={() => setNotificationsOpen(false)}
                      />
                    </div>,
                    document.body
                  )}
              </div>
              <div ref={agentSelectorRef} className="relative w-64 lg:w-auto">
                <AgentPersonaSelector
                  isOpen={agentSelectorOpen}
                  onToggle={toggleAgentSelector}
                />
              </div>
            </div>
          </header>
          <main className="flex-1 p-4 overflow-auto mt-16">
            {activeTab === 'overview' && <AgentOverview />}
            {activeTab === 'chat' && <ChatInterface />}
            {activeTab === 'knowledge' && <KnowledgeGraph />}
            {activeTab === 'tasks' && <TaskDashboard />}
            {activeTab === 'tools' && <ToolManagement />}
            {activeTab === 'improvement' && <SelfImprovementTracker />}
            {activeTab === 'evaluation' && <EvaluationDashboard />}
            {activeTab === 'agentConfig' && <AgentConfigurationInterface />}
            {activeTab === 'documents' && <DocumentLibrary />}
            {activeTab === 'settings' && <SettingsComponent />}
          </main>
        </div>
      </div>
    </AgentProvider>
  );
};

export default DashboardLayout;
