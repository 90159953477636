import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Zap, Binary, Brain, CheckCircle } from 'lucide-react';
import Logo from "../../assets/logo-brainchain-blue.png";
import DashboardImage from "../../assets/dashboard-image.png";
import { useInView } from 'react-intersection-observer';

export const LandingPage = () => {
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [email, setEmail] = useState('');
  const [dashboardError, setDashboardError] = useState<string | null>(null);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false
  });

  // Only redirect to dashboard if user explicitly tries to access it
  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Only check token if we're not already on the landing page
        if (window.location.pathname !== '/') {
          await getAccessTokenSilently();
          navigate("/c");
        }
      } catch (error) {
        console.log('No valid token found');
      }
    };

    if (!isLoading) {
      checkAuth();
    }
  }, [isLoading, getAccessTokenSilently, navigate]);

  // Animated headline component
  const AnimatedHeadline: React.FC = () => {
    return (
      <h1 className="text-5xl md:text-6xl lg:text-7xl font-extrabold text-gray-900 mb-6 leading-tight">
        <span className="block overflow-hidden">
          <span className="block animate-reveal-text">Automate Your</span>
        </span>
        <span className="block overflow-hidden">
          <span className="block animate-reveal-text" style={{ animationDelay: '0.2s' }}>Workflow with AI</span>
        </span>
      </h1>
    );
  };

  // Feature card component
  const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
    <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center hover:shadow-xl transition duration-300 transform hover:-translate-y-1">
      <div className="text-[#004DB5] mb-4 bg-blue-100 p-3 rounded-full">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Email submitted:', email);
    setEmail('');
  };

  // Navigation button logic
  const NavigationButton = () => {
    if (isLoading) {
      return null;
    }

    if (isAuthenticated) {
      return (
        <button
          onClick={() => navigate("/c")}
          className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-[#004DB5] hover:bg-blue-700 rounded-md transition duration-150 ease-in-out"
        >
          Dashboard
        </button>
      );
    }

    return (
      <button
        onClick={() => loginWithRedirect()}
        className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white bg-[#004DB5] hover:bg-blue-700 rounded-md transition duration-150 ease-in-out"
      >
        Sign In
      </button>
    );
  };

  return (
    <div className="min-h-screen bg-white text-gray-900">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-white bg-opacity-90 backdrop-blur-md z-50 shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <img src={Logo} alt="Brainchain Logo" className="h-8 mr-2" />
            <span className="font-bold text-2xl text-[#004DB5]">Autobot</span>
          </div>
          <div className="hidden md:flex space-x-8">
            <a href="#features" className="text-gray-600 hover:text-[#004DB5] transition duration-300">Features</a>
            <a href="#how-it-works" className="text-gray-600 hover:text-[#004DB5] transition duration-300">How it works</a>
            <a href="#waitlist" className="text-gray-600 hover:text-[#004DB5] transition duration-300">Join Waitlist</a>
          </div>
          <NavigationButton />
        </nav>
      </header>

      {/* Hero Section */}
      <main className="pt-20">
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 md:py-32">
          <div className="text-center max-w-3xl mx-auto">
            <AnimatedHeadline />
            <p className="text-xl text-gray-600 mb-10 leading-relaxed opacity-0 animate-fade-in" style={{ animationDelay: '0.8s', animationFillMode: 'forwards' }}>
              Autobot is your autonomous AI assistant for complex tasks and decision-making. Experience the future of business process automation today.
            </p>
            {isAuthenticated ? (
              <button
                onClick={() => navigate("/c")}
                className="bg-[#004DB5] text-white px-8 py-3 rounded-full hover:bg-blue-700 transition duration-300 flex items-center mx-auto"
              >
                Go to Dashboard <ArrowRight className="ml-2" size={20} />
              </button>
            ) : (
              <button
                onClick={() => loginWithRedirect()}
                className="bg-[#004DB5] text-white px-8 py-3 rounded-full hover:bg-blue-700 transition duration-300 flex items-center mx-auto"
              >
                Get Started <ArrowRight className="ml-2" size={20} />
              </button>
            )}
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="py-20 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center mb-12">Powerful Features for Your Business</h2>
            <div className="grid md:grid-cols-3 gap-12">
              <FeatureCard
                icon={<Zap size={32} />}
                title="Autonomous Execution"
                description="Plan, reason, and execute complex tasks without constant supervision"
              />
              <FeatureCard
                icon={<Binary size={32} />}
                title="Seamless Integration"
                description="Designed to work with your existing tools and adapt to your workflows"
              />
              <FeatureCard
                icon={<Brain size={32} />}
                title="Continuous Learning"
                description="Acquires domain expertise and improves over time"
              />
            </div>
          </div>
        </section>
      </main>

      {/* How it Works Section */}
      <section id="how-it-works" className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12">How Autobot Works</h2>
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <ul className="space-y-6">
                {[
                  'Understands conversations and user intent',
                  'Executes tasks using integrated tools and APIs',
                  'Builds and utilizes knowledge graphs for decision-making',
                  'Adapts to various industries and use cases',
                  'Provides real-time updates and transparent operations'
                ].map((item, index) => (
                  <li key={index} className="flex items-start">
                    <CheckCircle className="text-[#004DB5] mr-3 mt-1 flex-shrink-0" size={24} />
                    <span className="text-lg">{item}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="order-first md:order-last">
              {DashboardImage ? (
                <img 
                  src={DashboardImage} 
                  alt="Autobot Dashboard" 
                  className="rounded-lg shadow-lg"
                  onError={(e) => {
                    console.error("Error loading dashboard image:", e);
                    setDashboardError("Failed to load dashboard image");
                    e.currentTarget.style.display = 'none';
                  }}
                />
              ) : (
                <span className="text-red-500">Dashboard image import failed</span>
              )}
              {dashboardError && <span className="text-red-500 text-sm mt-2 block">{dashboardError}</span>}
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section with Sliding Autobot */}
      <section className="py-20 bg-[#004DB5] text-white overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div ref={ref} className="overflow-hidden w-full">
            <h2
              className={`text-6xl md:text-7xl font-extrabold mb-10 whitespace-nowrap transition-all duration-2000 ease-out ${
                inView ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
              }`}
            >
              Autobot
            </h2>
          </div>

          <h3 className="text-3xl md:text-4xl font-bold mb-6">Ready to transform your workflow?</h3>
          <p className="text-lg md:text-xl mb-10 max-w-2xl">
            Our platform powers highly adaptive, intelligent and specialized AI agents across industries.
          </p>
          {/* Email submission form */}
          <form onSubmit={handleSubmit} className="max-w-md mx-auto mb-12" id="waitlist">
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="email"
                placeholder="Enter your work email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-grow px-4 py-3 rounded-full border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
                required
              />
              <button
                type="submit"
                className="bg-[#004DB5] text-white px-6 py-3 rounded-full hover:bg-blue-700 transition duration-300 flex items-center justify-center"
              >
                Join Waitlist <ArrowRight className="ml-2" size={20} />
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-white text-gray-900 py-8 shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-6 mb-6">
            <div>
              <h3 className="font-bold text-xl mb-3 text-[#004DB5]">Autobot</h3>
              <p className="text-gray-600">AI-driven workflow automation.</p>
            </div>
            <div>
              <h4 className="font-semibold mb-3 text-gray-900">Product</h4>
              <ul className="space-y-2">
                <li><a href="#features" className="text-gray-600 hover:text-[#004DB5] transition duration-300">Features</a></li>
                <li><a href="#how-it-works" className="text-gray-600 hover:text-[#004DB5] transition duration-300">How it Works</a></li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-3 text-gray-900">Company</h4>
              <ul className="space-y-2">
                <li><a href="/about" className="text-gray-600 hover:text-[#004DB5] transition duration-300">About Us</a></li>
                <li><a href="/contact" className="text-gray-600 hover:text-[#004DB5] transition duration-300">Contact</a></li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold mb-3 text-gray-900">Stay Updated</h4>
              <a href="#waitlist" className="bg-[#004DB5] text-white px-4 py-2 rounded-full hover:bg-blue-700 transition duration-300 inline-block">
                Join Waitlist
              </a>
            </div>
          </div>
          <div className="border-t border-gray-200 pt-4 text-center text-gray-600">
            <p>&copy; 2024 Brainchain AI Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};
