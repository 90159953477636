import React, { useState, useEffect } from 'react';
import { User, Database, Trash2, Clock, AlertCircle, Settings as SettingsIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { DEFAULT_SETTINGS, clearAllData } from '../services/api/chatApi';
import { useAgentContext } from '../context/AgentContext';

interface UserSettings {
  dataRetentionPeriod: number;
}

const Settings: React.FC = () => {
  const { user, logout } = useAuth0();
  const { selectedAgent, setSelectedAgent } = useAgentContext();
  const [settings, setSettings] = useState<UserSettings>(() => {
    // Load settings from localStorage or use defaults
    const saved = localStorage.getItem('app:settings');
    return saved ? JSON.parse(saved) : DEFAULT_SETTINGS;
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('app:settings')) {
      localStorage.setItem('app:settings', JSON.stringify(DEFAULT_SETTINGS));
      setSettings(DEFAULT_SETTINGS);
    }
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numValue = parseInt(value, 10);
    
    if (numValue < 1 || numValue > 365) {
      toast.error('Retention period must be between 1 and 365 days');
      return;
    }

    const updatedSettings = {
      ...settings,
      [name]: numValue
    };
    setSettings(updatedSettings);
    localStorage.setItem('app:settings', JSON.stringify(updatedSettings));
    toast.success('Settings updated');
  };

  const handleClearAll = async () => {
    if (window.confirm('This will delete all your data except login information. You will be logged out. Are you sure?')) {
      try {
        await clearAllData();
        toast.success('All data cleared successfully');
        
        logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      } catch (error) {
        console.error('Failed to clear data:', error);
        toast.error('Failed to clear data');
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
      <h2 className="text-2xl font-bold text-gray-900">Settings</h2>

      {/* User Information */}
      <section className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <h3 className="text-xl font-semibold mb-6 flex items-center text-gray-900">
          <User className="mr-2 text-[#004DB5]" /> User Information
        </h3>
        <div className="grid gap-6 md:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <div className="mt-1 text-gray-900 py-2 font-medium">
              {user?.name}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <div className="mt-1 text-gray-900 py-2 font-medium">
              {user?.email}
            </div>
          </div>
        </div>
      </section>

      {/* Data Management */}
      <section className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <h3 className="text-xl font-semibold mb-6 flex items-center text-gray-900">
          <Database className="mr-2 text-[#004DB5]" /> Chat History & Data
        </h3>
        
        {/* Automatic Data Cleanup */}
        <div className="mb-8 p-4 bg-gray-50 rounded-lg border border-gray-200">
          <div className="flex items-start mb-4">
            <Clock className="mr-2 text-[#004DB5] mt-1" />
            <div>
              <h4 className="font-medium text-gray-900">Retention Period</h4>
              <p className="text-sm text-gray-600 mb-4">
                Set how long you want to keep your chat history. Older chats will be automatically cleaned up to save space.
              </p>
              <div className="inline-flex items-center px-3 py-1 bg-blue-50 rounded-full">
                <span className="text-sm text-[#004DB5]">
                  Currently keeping chats for {settings.dataRetentionPeriod} days
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <input
              type="number"
              id="dataRetentionPeriod"
              name="dataRetentionPeriod"
              value={settings.dataRetentionPeriod}
              onChange={handleInputChange}
              min="1"
              max="365"
              className="w-24 border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#004DB5] focus:border-[#004DB5]"
            />
            <span className="text-gray-700 font-medium">days</span>
            <span className="text-xs text-gray-500 ml-2">(1-365 days)</span>
          </div>
        </div>

        {/* Manual Data Cleanup */}
        <div className="p-4 bg-red-50 rounded-lg border border-red-200">
          <div className="flex items-start mb-4">
            <AlertCircle className="mr-2 text-red-600 mt-1" />
            <div>
              <h4 className="font-medium text-gray-900">Manual Data Cleanup</h4>
              <p className="text-sm text-gray-600 mb-4">
                Need to clear some data? Choose an option below. Note: Chat deletion follows your retention period setting above.
              </p>
            </div>
          </div>
          <div className="space-y-3">
            <button
              onClick={handleClearAll}
              className="w-full bg-red-600 text-white px-4 py-2.5 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 flex items-center justify-center font-medium transition-colors"
            >
              <Trash2 className="w-4 h-4 mr-2" />
              Clear All Data
            </button>
          </div>
        </div>
      </section>

      <div className="flex justify-end">
        <button className="bg-[#004DB5] text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#004DB5] font-medium">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default Settings;
