import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ApiChatMessage } from '../../types/chat';

interface MessageProps {
  message: ApiChatMessage;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  const isUser = message.role === 'user';

  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
      <div
        className={`max-w-[80%] rounded-lg p-4 ${
          isUser ? 'bg-[#D8E9FF]' : 'bg-[#F5F5F5]'
        }`}
      >
        <div className="prose prose-sm max-w-none">
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={{
              a: (props) => (
                <a
                  {...props}
                  className="text-blue-600 hover:text-blue-800 break-words"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
              img: (props) => (
                <img
                  {...props}
                  className="max-w-full h-auto rounded-lg my-2"
                  loading="lazy"
                  onError={(e) => {
                    e.currentTarget.src = '/placeholder-image.png';
                  }}
                />
              ),
              p: (props) => (
                <p {...props} className="mb-2 last:mb-0 break-words" />
              ),
              ul: (props) => (
                <ul {...props} className="list-disc pl-4 mb-2" />
              ),
              ol: (props) => (
                <ol {...props} className="list-decimal pl-4 mb-2" />
              ),
              code: (props) => (
                <code
                  {...props}
                  className="bg-gray-100 rounded px-1 block p-2"
                />
              )
            }}
          >
            {typeof message.content === 'string' ? message.content : JSON.stringify(message.content)}
          </ReactMarkdown>
        </div>
        <div className="text-xs text-gray-500 mt-1">
          {new Date(message.timestamp).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
};

export default Message;
