import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { getAuth0DomainName, getAuth0ClientId } from "./config/Auth0";
import DashboardLayout from "./components/layout/DashboardLayout";
import { LandingPage } from "./pages/LandingPage/LandingPage";

enum RouteNames {
  LANDING_PAGE = "/",
  LOGGED_IN_ROUTES = "/c",
  DOCUMENT_LIBRARY = "/document-library",
  CONVERSATION = "/conversation",
  CONVERSATIONS = "conversation/:id",
  KNOWLEDGE_GRAPH = "/knowledge_graph",
}

const Auth0ProviderWithRedirectCallback = ({
  children,
  redirectUri,
  ...props
}: {
  redirectUri: string;
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || RouteNames.LOGGED_IN_ROUTES);
  };

  return (
    <Auth0Provider
      domain={getAuth0DomainName()}
      clientId={getAuth0ClientId()}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

const ProtectedRoute = ({
  component,
  ...args
}: {
  component: React.ComponentType<object>;
}) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback redirectUri={window.location.origin}>
        <Routes>
          <Route path={RouteNames.LANDING_PAGE} element={<LandingPage />} />
          <Route
            path="/c"
            element={<ProtectedRoute component={DashboardLayout} />}
          >
            {/* <Route path="document_library" element={<DocumentLibraryPage />} />
            <Route path="conversation" element={<ConversationPage />} />
            <Route path="knowledge_graph" element={<KnowledgeGraphPage />} />
            <Route path="overview" element={<OverviewPage />} />
            <Route path="tasks" element={<TasksPage />} />
            <Route path="tools" element={<ToolsPage />} />
            <Route path="self-improvement" element={<SelfImprovementPage />} />
            <Route
              path="agent-configuration"
              element={<AgentConfigurationPage />}
            />
            <Route path="settings" element={<SettingsPage />} /> */}
          </Route>
        </Routes>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

export default App;
