import React, { useState, useEffect } from "react";
import { Search, Upload, X, RefreshCw } from "lucide-react";
import Tooltip from "../ui/Tooltip";
import { DocumentList } from "./DocumentList";
import { useAgentContext } from "../../context/AgentContext";
import { toast } from "react-hot-toast";
import { documentApi } from "../../api/documentApi";
import { 
  Document, 
  DocumentChunkExtended,
  DocumentWithChunksExtended 
} from "../../types/document";
import { API_BASE_URL } from "../../api/documentApi";
import { formatFileSize, formatTimeAgo } from '../../utils/formatters';
import { useAuth0 } from "@auth0/auth0-react";

// Add helper function for cleaning text
const cleanText = (text: string) => {
  return text
    .replace(/[^\w\s-]/g, ' ') // Replace special chars with spaces
    .replace(/\s+/g, ' ')      // Collapse multiple spaces
    .trim();
};

// Add file validation constants
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'text/csv',
  'application/json'
];

const validateFiles = (files: FileList): { valid: File[], errors: string[] } => {
  const validFiles: File[] = [];
  const errors: string[] = [];

  Array.from(files).forEach(file => {
    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      errors.push(`${file.name} is too large (max ${formatFileSize(MAX_FILE_SIZE)})`);
      return;
    }

    // Check file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      errors.push(`${file.name} has unsupported format (${file.type})`);
      return;
    }

    validFiles.push(file);
  });

  return { valid: validFiles, errors };
};

const DocumentLibrary: React.FC = () => {
  const { selectedAgent } = useAgentContext();
  const { user, isAuthenticated } = useAuth0();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<{[key: string]: number}>({});
  const [streamingResults, setStreamingResults] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(25);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const processDocument = (doc: DocumentWithChunksExtended, index: number): Document => {
    console.log('Processing document metadata:', doc.metadata);
    
    // Get author information from the first chunk if available
    const firstChunk = doc.chunks?.[0] as DocumentChunkExtended;
    const author = firstChunk?.authors?.[0];
    
    // Try to get uploader from author email, metadata, or fallback
    const uploader = author?.email || 
                    doc.metadata?.uploaded_by || 
                    doc.metadata?.user_id || 
                    'Unknown';
    
    console.log('Document author:', author);
    console.log('Document uploader:', uploader);

    // Get upload time from metadata if available
    const uploadTime = doc.metadata?.created_at || new Date().toISOString();
    const uploadedAgo = Math.round((Date.now() - new Date(uploadTime).getTime()) / 1000 / 60);
    
    // Try to get display name from metadata
    let displayName = doc.metadata?.title || 
                     doc.metadata?.filename ||
                     doc.metadata?.name ||
                     `Document ${index + 1}`;

    displayName = cleanText(displayName);
    
    if (displayName.length > 50) {
      displayName = displayName.substring(0, 47) + '...';
    }

    const baseDocument: Document = {
      id: `${doc.id || doc.doc_id || `doc_${index}`}_${index}`,
      original_id: doc.id || doc.doc_id || '',
      title: displayName,
      content: '',
      metadata: {
        type: doc.metadata?.type || doc.metadata?.file_type || 'unknown',
        created_at: doc.metadata?.created_at || new Date().toISOString(),
        updated_at: doc.metadata?.updated_at || new Date().toISOString(),
        size: doc.metadata?.size || doc.metadata?.file_size || 0,
        uploaded_by: doc.metadata?.uploaded_by || 'Unknown',
        tags: doc.metadata?.tags || [],
        status: 'processing'
      }
    };

    // Check if document is still processing
    if (!doc.chunks || !Array.isArray(doc.chunks) || doc.chunks.length === 0) {
      console.log(`Document ${doc.id} status: Processing (uploaded ${uploadedAgo} minutes ago)`);
      
      return {
        ...baseDocument,
        content: 'Document is being processed...',
        metadata: {
          ...baseDocument.metadata,
          status: uploadedAgo > 5 ? 'error' : 'processing'
        }
      };
    }

    // Document has chunks, process as complete
    console.log(`Document ${doc.id} status: Complete`);

    return {
      ...baseDocument,
      content: doc.chunks.map(chunk => chunk.content || '').join('\n'),
      metadata: {
        ...baseDocument.metadata,
        created_at: firstChunk.created_at || doc.metadata?.created_at || new Date().toISOString(),
        updated_at: firstChunk.updated_at || doc.metadata?.updated_at || new Date().toISOString(),
        uploaded_by: uploader,
        ...(author?.name && { author_name: author.name }),
        ...(author?.email && { author_email: author.email }),
        ...(author?.affiliation && { author_affiliation: author.affiliation }),
        status: 'complete'
      }
    };
  };

  const fetchDocuments = async () => {
    if (!selectedAgent) {
      console.warn('No agent selected');
      return;
    }

    const userId = isAuthenticated && user?.email ? user.email : 'default_user';
    console.log('Fetching documents for user:', userId);

    setIsLoading(true);
    try {
      const response = await documentApi.retrieveDocuments(
        selectedAgent.id,
        userId,  // Use Auth0 user email
        { limit: 100 }
      );

      console.log('Document retrieve response received');

      if (!response.documents || !Array.isArray(response.documents)) {
        console.error('Invalid response format - documents array missing:', response);
        setDocuments([]);
        return;
      }

      // Count documents by status
      const processingDocs = response.documents.filter(doc => !doc.chunks?.length).length;
      const completeDocs = response.documents.filter(doc => doc.chunks?.length > 0).length;

      console.log(`Document status summary: ${completeDocs} complete, ${processingDocs} processing`);

      // Update UI with processing status
      setStreamingResults(prev => [
        ...prev,
        `Found ${response.documents.length} total documents`,
        `${processingDocs} documents still processing`,
        `${completeDocs} documents ready to view`,
        processingDocs > 0 ? '⚠️ Some documents are still being processed and may need to be refreshed later.' : ''
      ].filter(Boolean));

      // Process all documents
      const processedDocuments = response.documents.map((doc, index) => 
        processDocument(doc, index)
      );

      setDocuments(processedDocuments);

    } catch (error: unknown) {
      console.error('Error fetching documents:', error);
      toast.error('Failed to load documents');
      
      // Type guard for error message
      let errorMessage = 'Unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      } else if (error && typeof error === 'object' && 'message' in error) {
        errorMessage = String(error.message);
      }
      
      setStreamingResults(prev => [...prev, `Error: ${errorMessage}`]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedAgent) {
      console.log('Loading documents for agent:', selectedAgent.id);
      fetchDocuments();
    } else {
      setDocuments([]);
      setStreamingResults(['Please select an agent to view their documents']);
    }
  }, [selectedAgent]);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || !selectedAgent) return;

    // Use Auth0 user email as user_id
    const userId = isAuthenticated && user?.email ? user.email : 'default_user';
    console.log('Uploading with user ID:', userId);

    // Validate files first
    const { valid, errors } = validateFiles(files);
    
    if (errors.length > 0) {
      errors.forEach(error => toast.error(error));
      setStreamingResults(prev => [...prev, '❌ Upload validation failed:', ...errors]);
      return;
    }

    if (valid.length === 0) {
      toast.error('No valid files to upload');
      return;
    }

    setUploading(true);
    setStreamingResults(prev => [
      ...prev, 
      `Starting upload of ${valid.length} file(s)...`,
      `Total size: ${formatFileSize(valid.reduce((sum, file) => sum + file.size, 0))}`
    ]);

    try {
      await documentApi.uploadDocuments(
        selectedAgent.id,
        userId,  // Use Auth0 user email
        valid,
        // Progress callback
        (progress) => {
          setUploadProgress(prev => ({
            ...prev,
            'overall': progress
          }));
          
          // Update for each file (assuming equal progress)
          const fileProgress: Record<string, number> = {};
          valid.forEach(file => {
            fileProgress[file.name] = progress;
          });
          setUploadProgress(prev => ({
            ...prev,
            ...fileProgress
          }));
        },
        // Stream update callback
        (message) => {
          setStreamingResults(prev => [...prev, message]);
        }
      );

      setStreamingResults(prev => [
        ...prev, 
        '✅ Upload complete',
        'Processing files...',
        'Documents will appear in the list when ready'
      ]);
      toast.success(`Successfully uploaded ${valid.length} file(s)`);
      
      // Refresh document list
      await fetchDocuments();

    } catch (error: any) {
      console.error('Error uploading files:', error);
      const errorMessage = error.response?.data?.detail || error.message || 'Unknown error';
      toast.error(`Upload failed: ${errorMessage}`);
      setStreamingResults(prev => [
        ...prev, 
        `❌ Upload error: ${errorMessage}`,
        'Please try again or contact support if the problem persists'
      ]);
    } finally {
      setUploading(false);
      setUploadProgress({});
      // Clear the file input
      event.target.value = '';
    }
  };

  const handleSearch = async (term: string) => {
    setSearchTerm(term);
    if (!selectedAgent) return;

    setIsLoading(true);
    try {
      const response = await documentApi.searchDocuments(selectedAgent.id, term);
      console.log('Search results:', response);
      
      // Transform documents same as before
      const transformedDocuments = response.results
        .map((result, index) => {
          const doc = result.node;
          console.log('Complete document:', JSON.stringify(doc, null, 2));

          if (!doc) {
            console.warn('Document node is missing:', result);
            return null;
          }

          // Try to extract a meaningful title
          let displayName = '';
          
          // Helper function to clean text
          const cleanText = (text: string) => {
            return text
              .replace(/[^\w\s-]/g, ' ') // Replace special chars with spaces
              .replace(/\s+/g, ' ')      // Collapse multiple spaces
              .trim();
          };

          // Try to find a meaningful title from content
          if (doc.content) {
            const lines = doc.content.split('\n').map(line => line.trim()).filter(line => line);
            
            // Look for common document title patterns
            const titlePatterns = [
              // Chapter or section headings
              /^(?:Chapter|Section)\s+\d+[:.]?\s*(.+)/i,
              // Table headings
              /^Table\s+\d+[:.]?\s*(.+)/i,
              // Numbered headings
              /^(?:\d+\.|\[[\d\.]+\])\s*(.+)/,
              // File paths or names
              /\/([^\/]+\.[a-zA-Z0-9]+)$/,
              // All caps headings
              /^[A-Z][A-Z\s]{3,}(?:\s+\d+)?$/,
              // Title case headings
              /^(?:[A-Z][a-z]+\s*){2,}$/
            ];

            // Try each pattern
            for (const pattern of titlePatterns) {
              const match = lines.find(line => pattern.test(line));
              if (match) {
                displayName = match;
                break;
              }
            }

            // If no pattern matches, use first non-empty line that's not just numbers/symbols
            if (!displayName) {
              displayName = lines.find(line => {
                const cleaned = cleanText(line);
                return cleaned.length > 0 && 
                       cleaned.length < 100 &&
                       /[a-zA-Z]{2,}/.test(cleaned); // At least 2 letters
              }) || '';
            }
          }

          // Clean up the title
          displayName = cleanText(displayName);
          
          // Truncate with ellipsis if too long
          if (displayName.length > 50) {
            displayName = displayName.substring(0, 47) + '...';
          }

          // Fallback to document type or number if no good title found
          if (!displayName || displayName.length < 3) {
            const docType = doc.metadata?.type || 'Document';
            displayName = `${docType} ${index + 1}`;
          }

          return {
            id: `${doc.id || doc.doc_id}_${index}`,
            original_id: doc.id || doc.doc_id || '',
            title: displayName,
            content: doc.content || '',
            metadata: {
              type: doc.metadata?.type || doc.metadata?.file_type || 'unknown',
              created_at: doc.metadata?.created_at || doc.created_at || new Date().toISOString(),
              updated_at: doc.metadata?.updated_at || doc.updated_at || new Date().toISOString(),
              size: doc.metadata?.size || doc.metadata?.file_size || 0,
              uploaded_by: doc.metadata?.uploaded_by || 'Unknown',
              tags: doc.metadata?.tags || []
            }
          };
        })
        .filter((doc): doc is NonNullable<typeof doc> => doc !== null);

      setDocuments(transformedDocuments);
    } catch (error) {
      console.error('Error searching documents:', error);
      toast.error('Failed to search documents');
    } finally {
      setIsLoading(false);
    }
  };

  const generateVectorIndex = async () => {
    try {
      // TODO: Implement actual API call
      // await fetch('/document_ingestion/api/v1/knowledge/rag/generate_vector_index', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({
      //     index_name: 'document_index',
      //     node_label: 'Document',
      //     embedding_property: 'embedding',
      //     vector_dimensions: 768,
      //     similarity_function: 'cosine'
      //   })
      // });
      console.log("Vector index generated");
      setStreamingResults((prev) => [
        ...prev,
        "Vector index generated successfully.",
      ]);
    } catch (error) {
      console.error("Error generating vector index:", error);
      setStreamingResults((prev) => [
        ...prev,
        "Error occurred while generating vector index.",
      ]);
    }
  };

  const reindexDocumentChunks = async () => {
    try {
      // TODO: Implement actual API call
      // const response = await fetch('/document_ingestion/api/v1/knowledge/reindex_document_chunks', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ batch_size: 100 })
      // });
      // const reader = response.body.getReader();
      // while (true) {
      //   const { done, value } = await reader.read();
      //   if (done) break;
      //   console.log(new TextDecoder().decode(value));
      // }
      console.log("Document chunks reindexed");
      setStreamingResults((prev) => [
        ...prev,
        "Document chunks reindexed successfully.",
      ]);
    } catch (error) {
      // Add a modal for error handling or display a toast notification
      // console.error("Error reindexing document chunks:", error);
      // setStreamingResults((prev) => [
      //   ...prev,
      //   "Error occurred while reindexing document chunks.",
      // ]);
    }
  };

  // Add handlers for DocumentList props
  const handleViewDocument = (doc: Document) => {
    setSelectedDocument(doc);
    setIsViewModalOpen(true);
  };

  const handleUpdateDocument = async (id: string, metadata: any) => {
    if (!selectedAgent) return;
    try {
      // Extract the original ID by removing the index suffix
      const originalId = id.split('_')[0];
      const response = await documentApi.updateDocument(selectedAgent.id, originalId, metadata);
      if (response.status === 'success') {
        toast.success('Document updated successfully');
        await fetchDocuments(); // Refresh list
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error updating document:', error);
      toast.error('Failed to update document');
    }
  };

  const handleDeleteDocument = async (id: string) => {
    if (!selectedAgent) return;
    try {
      // Extract the original ID by removing the index suffix
      const originalId = id.split('_')[0];
      const response = await documentApi.deleteDocument(selectedAgent.id, originalId);
      if (response.status === 'success') {
        toast.success('Document deleted successfully');
        await fetchDocuments(); // Refresh list
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      toast.error('Failed to delete document');
    }
  };

  const handleRetryDocument = async (id: string) => {
    if (!selectedAgent) return;
    try {
      // Extract the original ID by removing the index suffix
      const originalId = id.split('_')[0];
      const response = await documentApi.retryDocument(selectedAgent.id, originalId);
      if (response.status === 'success') {
        toast.success('Document processing restarted');
        await fetchDocuments(); // Refresh list
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error('Error retrying document:', error);
      toast.error('Failed to retry document processing');
    }
  };

  const groupedDocuments = documents.reduce((acc, doc) => {
    const status = doc.metadata.status || 'unknown';
    if (!acc[status]) acc[status] = [];
    acc[status].push(doc);
    return acc;
  }, {} as Record<string, Document[]>);

  return (
    <div className="max-w-6xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6" style={{ color: "#004DB5" }}>
        Document Library
      </h2>

      {/* Add loading state */}
      {isLoading && (
        <div className="text-center py-4">
          <p>Loading documents...</p>
        </div>
      )}

      {/* Add error state */}
      {!isLoading && documents.length === 0 && (
        <div className="text-center py-4">
          <p className="text-gray-500">
            {selectedAgent ? 'No documents found' : 'Please select an agent to view documents'}
          </p>
        </div>
      )}

      <div className="mb-6 flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="w-full sm:w-auto mb-4 sm:mb-0">
          <div className="relative w-full sm:w-64 mb-4 sm:mb-0">
            <input
              type="text"
              placeholder="Search documents..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="w-full p-2 pl-10 border rounded-lg shadow-sm"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full sm:w-auto space-y-2 sm:space-y-0 sm:space-x-2">
          <div className="relative group">
            <label
              htmlFor="document-upload"
              className="inline-flex items-center px-4 py-2 bg-[#004DB5] text-white rounded-lg hover:bg-blue-700 cursor-pointer transition-colors"
            >
              <Upload className="w-5 h-5 mr-2" />
              Upload Documents
            </label>
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
              <div className="relative">
                Supported files: PDF, Word, TXT, CSV, JSON (max {formatFileSize(MAX_FILE_SIZE)})
                <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full w-2 h-2 bg-gray-900 rotate-45"></div>
              </div>
            </div>
            <input
              type="file"
              id="document-upload"
              className="hidden"
              onChange={handleFileUpload}
              multiple
              accept={ALLOWED_FILE_TYPES.join(',')}
            />
          </div>
          <div className="relative group">
            <button
              onClick={() => {
                console.log('Manual refresh requested');
                fetchDocuments();
              }}
              className="bg-[#004DB5] text-white px-4 py-2 rounded-lg hover:bg-blue-700 flex items-center justify-center transition-colors"
              disabled={isLoading}
            >
              <RefreshCw className={`h-5 w-5 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
              Refresh
            </button>
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
              <div className="relative">
                Update document list with latest changes
                <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full w-2 h-2 bg-gray-900 rotate-45"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(uploading || streamingResults.length > 0) && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Upload Status</h3>
          <div className="bg-gray-100 p-4 rounded-lg">
            {/* Show progress bars only while uploading */}
            {uploading && (
              <div className="mb-4">
                {Object.entries(uploadProgress).map(([fileName, progress]) => (
                  <div key={fileName} className="mb-2">
                    <div className="flex justify-between text-sm mb-1">
                      <span>{fileName}</span>
                      <span>{progress}%</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2">
                      <div
                        className="bg-[#004DB5] h-2 rounded-full transition-all duration-300"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Status messages */}
            <div className="max-h-40 overflow-y-auto">
              {streamingResults.map((result, index) => (
                <div key={index} className="text-sm mb-1">
                  {result}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {!isLoading && documents.length > 0 && (
        Object.entries(groupedDocuments).map(([status, docs]) => (
          <div key={status} className="mb-6">
            <h3 className="text-lg font-semibold mb-2 capitalize">
              {status} Documents ({docs.length})
            </h3>
            <DocumentList 
              documents={docs}
              onView={handleViewDocument}
              onUpdate={handleUpdateDocument}
              onDelete={handleDeleteDocument}
              onRetry={handleRetryDocument}
            />
          </div>
        ))
      )}

      {isViewModalOpen && selectedDocument && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
            {/* Modal Header */}
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-lg font-semibold">{selectedDocument.title}</h3>
              <button 
                onClick={() => setIsViewModalOpen(false)}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            {/* Modal Content */}
            <div className="p-4 overflow-y-auto flex-grow">
              {/* Document Status */}
              <div className="mb-4">
                <div className={`inline-flex items-center px-3 py-1 rounded-full text-sm ${
                  selectedDocument.metadata.status === 'processing' ? 'bg-yellow-100 text-yellow-800' :
                  selectedDocument.metadata.status === 'error' ? 'bg-red-100 text-red-800' :
                  'bg-green-100 text-green-800'
                }`}>
                  <span className="mr-2">
                    {selectedDocument.metadata.status === 'processing' && '⏳'}
                    {selectedDocument.metadata.status === 'error' && '❌'}
                    {selectedDocument.metadata.status === 'complete' && '✓'}
                  </span>
                  {selectedDocument.metadata.status === 'processing' && 'Processing'}
                  {selectedDocument.metadata.status === 'error' && 'Failed to Process'}
                  {selectedDocument.metadata.status === 'complete' && 'Ready'}
                </div>
              </div>

              {/* Document Metadata */}
              <div className="mb-4 text-sm text-gray-600">
                <p>Type: {selectedDocument.metadata.type || 'Unknown'}</p>
                <p>Uploaded: {formatTimeAgo(selectedDocument.metadata.created_at || '')} ({new Date(selectedDocument.metadata.created_at || '').toLocaleString()})</p>
                <p>Last Updated: {formatTimeAgo(selectedDocument.metadata.updated_at || '')} ({new Date(selectedDocument.metadata.updated_at || '').toLocaleString()})</p>
                <p>Uploaded by: {selectedDocument.metadata.uploaded_by}</p>
              </div>
              
              {/* Error Message if applicable */}
              {selectedDocument.metadata.status === 'error' && (
                <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
                  <p className="font-semibold">Processing Failed</p>
                  <p>The document could not be processed. Please try uploading it again.</p>
                </div>
              )}
              
              {/* Document Content */}
              <div className="mt-4 p-4 bg-gray-50 rounded-lg whitespace-pre-wrap font-mono text-sm">
                {selectedDocument.metadata.status === 'processing' ? (
                  <div className="text-center text-gray-500">
                    <p>Document is being processed...</p>
                    <p className="text-sm mt-2">This may take a few minutes</p>
                  </div>
                ) : selectedDocument.metadata.status === 'error' ? (
                  <div className="text-center text-red-500">
                    <p>Content not available</p>
                    <p className="text-sm mt-2">Document processing failed</p>
                  </div>
                ) : (
                  selectedDocument.content
                )}
              </div>
            </div>
            
            {/* Modal Footer */}
            <div className="border-t p-4 flex justify-end">
              <button
                onClick={() => setIsViewModalOpen(false)}
                className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentLibrary;
