import React, { useState, useCallback, useEffect, useRef } from 'react';
import ForceGraph2D, { 
  ForceGraphMethods,
  NodeObject,
  LinkObject 
} from 'react-force-graph-2d';
import { useAgentContext } from '../../context/AgentContext';
import axios from 'axios';
import * as d3 from 'd3';
import { toast } from 'react-toastify';
import { Button } from '../../components/ui/button';
import { Card } from '../../components/ui/card';

// Define interfaces for the data structures used in the component
interface GraphNode {
  id: string;
  name: string;
  group: string;
  val: number;
  agentId: string;
  parentId?: string;
  data?: any;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
}

interface Link {
  source: string | GraphNode;
  target: string | GraphNode;
}

interface GraphData {
  nodes: GraphNode[];
  links: Link[];
}

// Extend GraphNode with D3 simulation properties
interface ExtendedGraphNode extends d3.SimulationNodeDatum {
  id: string;
  name: string;
  group: string;
  val: number;
  agentId: string;
  parentId?: string;
  data?: any;
  x?: number;
  y?: number;
  vx?: number;
  vy?: number;
  index?: number;
  fx?: number | null;
  fy?: number | null;
}

// API endpoint for fetching agent data
const API_BASE_URL = 'https://graph-test.brainchain.cloud/api/v1';

// Add interfaces for saved state
interface SavedGraphState {
  selectedNodeTypes: string[];
  camera: {
    x: number;
    y: number;
    zoom: number;
  };
  selectedNode: GraphNode | null;
  lastUpdated: string;
}

// Add storage keys
const STORAGE_KEYS = {
  graphData: 'knowledge_graph_data',
  filters: 'knowledge_graph_filters',
  lastAgent: 'knowledge_graph_last_agent'
};

// Add these new interfaces near the top of the file
interface CategoryState {
  isDiscovered: boolean;
  isVisible: boolean;
}

interface CategoryStates {
  [key: string]: CategoryState;
}

// Add new interface for loading states
interface CategoryLoadingState {
  [key: string]: boolean;
}

// Add these helper functions at the top level
const TRANSITION_DURATION = 800;
const NODE_SIZES = {
  agent: 40,
  category: 30,
  leaf: 20
};

// Add these interfaces near the top with other interfaces
interface GraphNodeWithPosition extends GraphNode {
  x?: number;
  y?: number;
}

interface CategoryNodesResponse {
  nodes: GraphNode[];
  links: Link[];
}

// Add the fetchCategoryNodes function
const fetchCategoryNodes = async (category: string, agentId: string): Promise<CategoryNodesResponse> => {
  try {
    // Get the full agent data which already contains all nodes
    const response = await axios.get(`${API_BASE_URL}/node/Agent?id=${agentId}&connected=true`);
    
    if (!response.data || !response.data.connected_nodes) {
      console.warn('Invalid agent data response:', response.data);
      return { nodes: [], links: [] };
    }

    // Filter nodes by category/type
    const categoryNodes = response.data.connected_nodes.filter((node: any) => {
      const nodeType = node.labels?.[0]?.toLowerCase();
      return nodeType === category.toLowerCase() ||
             (category === 'other' && !node.labels);
    });

    // Map the filtered nodes to our graph format
    const nodes: GraphNode[] = categoryNodes.map((node: any) => ({
      id: `${agentId}_${node.id || node.element_id}`,
      name: node.properties?.name || node.name || 'Unnamed Node',
      group: category,
      val: 15,
      agentId: agentId,
      parentId: `${agentId}_category_${category}`
    }));

    // Create links from category node to each child
    const links: Link[] = nodes.map(node => ({
      source: node.parentId!,
      target: node.id
    }));

    return { nodes, links };
  } catch (error) {
    console.error(`Error processing ${category} nodes:`, error);
    return { nodes: [], links: [] };
  }
};

// Add this near the top with other interfaces
interface LoadedData {
  [key: string]: boolean;
}

// First, let's update the state interfaces at the top
interface AgentGraphState {
  nodes: GraphNode[];
  links: Link[];
  categories: Set<string>;
  lastUpdated: number;
}

interface AgentGraphCache {
  [agentId: string]: AgentGraphState;
}

// Move SimLink interface to the top with other interfaces
interface SimLink extends d3.SimulationLinkDatum<ExtendedGraphNode> {
  source: string | ExtendedGraphNode;
  target: string | ExtendedGraphNode;
}

interface AgentDataResponse {
  nodes: GraphNode[];
  relationships: Link[];
}

// Update the color mapping function
const getNodeColor = (group: string) => {
  switch (group.toLowerCase()) {
    // Core node
    case 'agent':
      return '#1A237E';  // Darker Blue - more readable

    // Knowledge types
    case 'goals':
      return '#D84315';  // Deep Orange - strong contrast
    case 'project':
      return '#2E7D32';  // Dark Green - clear visibility
    case 'research_interests':
    case 'researchinterest':
      return '#C62828';  // Dark Red - strong contrast
    case 'knowledge_base':
    case 'knowledgebase':
      return '#0D47A1';  // Strong Blue - clear

    // Interaction types
    case 'system_prompt':
    case 'systemprompt':
      return '#4A148C';  // Dark Purple - readable
    case 'user_intents':
    case 'userintent':
      return '#0277BD';  // Strong Light Blue
    case 'impliedintent':
      return '#006064';  // Dark Cyan - clear

    // Tool types
    case 'tools':
    case 'tool':
      return '#BF360C';  // Deep Orange - strong
    case 'agent_scratchpad':
    case 'agentscratchpad':
      return '#1B5E20';  // Dark Green - clear
    case 'workspace_directory':
    case 'workspacedirectory':
      return '#E65100';  // Dark Orange - readable

    // Special types
    case 'sentiment':
      return '#880E4F';  // Dark Pink - clear
    case 'mailbox':
      return '#4A148C';  // Dark Purple - readable
    case 'document':
      return '#1565C0';  // Strong Blue - clear
    case 'category':
      return '#283593';  // Dark Indigo - readable

    // Default
    default:
      return '#455A64';  // Blue Grey - readable
  }
};

// At the top of the file, after imports
type ForceGraphNode = NodeObject<ExtendedGraphNode>;
type ForceGraphLink = LinkObject<ExtendedGraphNode, Link>;
type ForceGraphInstance = ForceGraphMethods<ForceGraphNode, ForceGraphLink>;

const useForceGraph = () => {
  const fgRef = useRef<ForceGraphInstance>();
  
  const initGraph = useCallback((instance: ForceGraphInstance | null) => {
    if (instance) {
      console.log('🔧 Initializing Force Graph instance');
      fgRef.current = instance;
      instance
        .d3Force('charge', d3.forceManyBody().strength(-200))
        .d3Force('center', d3.forceCenter())
        .d3Force('collision', d3.forceCollide().radius(30))
        .d3Force('link', d3.forceLink().distance(100));
    }
  }, []);

  return {
    fgRef,
    initGraph: initGraph as any
  };
};

const KnowledgeGraph: React.FC = () => {
  const { fgRef, initGraph } = useForceGraph();
  const [isLoading, setIsLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [graphData, setGraphData] = useState<GraphData>({ nodes: [], links: [] });
  
  const graphContainerRef = useRef<HTMLDivElement>(null);

  const { selectedAgent } = useAgentContext();

  // Load initial data
  useEffect(() => {
    const loadInitialData = async () => {
      if (!selectedAgent?.id) return;

      try {
        console.log('📊 Loading graph data for agent:', selectedAgent.id);
        setIsLoading(true);
        
        const response = await axios.get(`${API_BASE_URL}/node/Agent`, {
          params: {
            agent_id: selectedAgent.id,
            connected: true
          }
        });

        if (!response.data?.node || !response.data?.connected_nodes) {
          throw new Error('Invalid API response structure');
        }

        // Create main agent node
        const mainNode: GraphNode = {
          id: selectedAgent.id,
          name: selectedAgent.name || selectedAgent.id,
          group: 'agent',
          val: NODE_SIZES.agent,
          agentId: selectedAgent.id
        };

        const nodes: GraphNode[] = [mainNode];
        const links: Link[] = [];
        const processedNodes = new Set<string>();

        // Create category nodes first
        const categories = [
          'project', 'goals', 'system_prompt', 'research_interests', 'user_intents',
          'tools', 'agent_scratchpad', 'workspace_directory', 'sentiment',
          'impliedintent', 'knowledge_base', 'mailbox', 'other'
        ];

        // Add category nodes
        const categoryNodes = categories.map(category => ({
          id: `category_${category}_${selectedAgent.id}`,
          name: category.replace('_', ' ').charAt(0).toUpperCase() + category.replace('_', ' ').slice(1),
          group: category,
          val: NODE_SIZES.category,
          agentId: selectedAgent.id
        }));

        nodes.push(...categoryNodes);
        
        // Link agent to categories
        categoryNodes.forEach(categoryNode => {
          links.push({ 
            source: mainNode.id, 
            target: categoryNode.id 
          });
        });

        // Process connected nodes
        response.data.connected_nodes.forEach((node: any) => {
          if (!node?.id && !node?.element_id) return;

          const nodeId = node.id || node.element_id;
          if (processedNodes.has(nodeId)) return;
          processedNodes.add(nodeId);

          const nodeType = node.type || 
                          (Array.isArray(node.labels) && node.labels[0]) || 
                          'other';
          
          let category = 'other';
          switch (nodeType.toLowerCase()) {
            case 'goal': category = 'goals'; break;
            case 'project': category = 'project'; break;
            case 'systemprompt': category = 'system_prompt'; break;
            case 'researchinterest': category = 'research_interests'; break;
            case 'userintent': category = 'user_intents'; break;
            case 'tool': category = 'tools'; break;
            case 'agentscratchpad': category = 'agent_scratchpad'; break;
            case 'workspacedirectory': category = 'workspace_directory'; break;
            case 'sentiment': category = 'sentiment'; break;
            case 'impliedintent': category = 'impliedintent'; break;
            case 'knowledgebase': category = 'knowledge_base'; break;
            case 'mailbox': category = 'mailbox'; break;
          }

          // Create child node
          const childNode: GraphNode = {
            id: nodeId,
            name: node.name || node.properties?.name || 'Unnamed Node',
            group: category,
            val: NODE_SIZES.leaf,
            agentId: selectedAgent.id,
            parentId: `category_${category}_${selectedAgent.id}`,
            data: node.properties
          };

          nodes.push(childNode);
          
          // Link category to child
          links.push({ 
            source: `category_${category}_${selectedAgent.id}`, 
            target: nodeId 
          });
        });

        setGraphData({ nodes, links });

      } catch (error) {
        console.error('❌ Error loading graph data:', error);
        toast.error('Failed to load graph data');
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, [selectedAgent?.id]);

  // Monitor graph container size
  useEffect(() => {
    if (!isLoading && graphContainerRef.current) {
      console.log('📐 Graph container dimensions:', {
        width: graphContainerRef.current.clientWidth,
        height: graphContainerRef.current.clientHeight
      });
    }
  }, [isLoading]);

  // Debug graph data changes
  useEffect(() => {
    console.log('🔄 Graph data updated:', graphData);
  }, [graphData]);

  return (
    <div className="w-full h-full p-4">
      <Card className="w-full h-[calc(100vh-12rem)] overflow-hidden">
        <div ref={graphContainerRef} className="w-full h-full relative">
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
            </div>
          ) : (
            <ForceGraph2D
              ref={initGraph}
              width={graphContainerRef.current?.clientWidth || 800} // Provide fallback width
              height={graphContainerRef.current?.clientHeight || 600} // Provide fallback height
              graphData={graphData}
              nodeId="id"
              nodeVal={node => (node as ExtendedGraphNode).val}
              nodeLabel={node => (node as ExtendedGraphNode).name}
              nodeCanvasObject={(node: ExtendedGraphNode, ctx: CanvasRenderingContext2D, globalScale: number) => {
                const label = node.name;
                const fontSize = node.group === 'agent' ? 16/globalScale : 12/globalScale;
                
                ctx.font = `${fontSize}px Sans-Serif`;
                const textWidth = ctx.measureText(label).width;
                const padding = 4/globalScale;
                
                ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
                ctx.fillRect(
                  node.x! - textWidth/2 - padding,
                  node.y! - fontSize/2 - padding,
                  textWidth + padding * 2,
                  fontSize + padding * 2
                );
                
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = getNodeColor(node.group);
                ctx.fillText(label, node.x!, node.y!);
              }}
              nodeColor={node => getNodeColor((node as ExtendedGraphNode).group)}
              linkColor={() => "#999999"}
              linkWidth={1}
              onNodeClick={(node) => {
                console.log('👆 Clicked node:', node);
              }}
              onNodeDragEnd={node => {
                console.log('🔄 Node dragged:', node);
              }}
              cooldownTicks={100}
              onEngineStop={() => {
                if (fgRef.current && !initialLoadComplete) {
                  console.log('🎯 Zooming to fit graph');
                  fgRef.current.zoomToFit(400);
                  setInitialLoadComplete(true);
                }
              }}
              d3VelocityDecay={0.1}
              d3AlphaMin={0.001}
              d3AlphaDecay={0.0228}
              warmupTicks={50}
              cooldownTime={2000}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default KnowledgeGraph;