import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Send, Menu, X, Plus, Paperclip } from 'lucide-react';
import {
  ApiChatMessage,
  SavedChat,
  DraftChat,
  CommandResult,
  GraphContext,
} from '../../types/chat';
import { chatApi, saveChatData, saveChatMetadata, loadChatList, loadChatData, getActiveChat, generateTempId, cleanupConversations, ensureSettings, clearAllData } from '../../services/api/chatApi';
import { useAgentContext } from '../../context/AgentContext';
import Message from './Message';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Image } from 'lucide-react';
import { documentApi } from "../../api/documentApi";
import { useAuth0 } from "@auth0/auth0-react";
import { formatFileSize } from '../../utils/formatters';
import { debounce } from 'lodash';
import isEqual from 'lodash/isEqual';

// Add API base URL from config
const API_BASE_URL = 'https://am-test.brainchain.cloud';

const generateUniqueId = (prefix: string) => {
  const timestamp = Date.now();
  const random = crypto.getRandomValues(new Uint32Array(1))[0];
  return `${prefix}_${timestamp}_${random.toString(36)}`;
};

const generateTitleFromMessage = (content: ApiChatMessage['content']): string => {
  // Convert content to string based on its type
  const textContent = typeof content === 'string' 
    ? content 
    : typeof content === 'object' && content !== null
      ? JSON.stringify(content)
      : 'New Chat';

  // Take first 5 words, or fewer if the message is shorter
  const words = textContent.split(' ').slice(0, 5).join(' ');
  // Add ellipsis if the message is longer than 5 words
  return textContent.split(' ').length > 5 ? `${words}...` : words;
};

// Update the formatRelativeTime function to be more precise
const formatRelativeTime = (timestamp: string) => {
  const now = Date.now();
  const date = new Date(timestamp).getTime();
  const diffInSeconds = Math.floor((now - date) / 1000);
  
  // More precise "just now" calculation
  if (diffInSeconds < 5) return 'Just now';
  if (diffInSeconds < 60) return `${diffInSeconds}s ago`;
  if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes}m ago`;
  }
  if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours}h ago`;
  }
  if (diffInSeconds < 172800) return 'Yesterday';
  
  // For older messages, show the full date and time
  return new Date(timestamp).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

// Update the ConversationListItem component with better spacing
const ConversationListItem: React.FC<{
  conversation: SavedChat;
  isActive: boolean;
  onSelect: () => void;
  onDelete: () => void;
  agentName?: string;
}> = ({ conversation, isActive, onSelect, onDelete, agentName }) => {
  // Get display title
  const getDisplayTitle = (conversation: SavedChat): string => {
    if (conversation.messages.length > 0) {
      const firstMessage = conversation.messages[0];
      const content = firstMessage.content;
      return generateTitleFromMessage(content);
    }
    return conversation.title || 'New Chat';
  };

  // Get the timestamp from the last message if it exists
  const getLastMessageTimestamp = (conversation: SavedChat): string => {
    if (conversation.messages && conversation.messages.length > 0) {
      return conversation.messages[conversation.messages.length - 1].timestamp;
    }
    // Fallback to conversation updated_at if no messages
    return conversation.updated_at;
  };

  return (
    <div 
      className={`h-[76px] flex flex-col justify-center px-4 cursor-pointer border-b border-gray-200 ${
        isActive ? 'bg-blue-100' : 'hover:bg-gray-50'
      }`}
      onClick={onSelect}
    >
      <div className="flex items-start justify-between w-full">
        <div className="flex-1 min-w-0 flex flex-col gap-2">
          {/* Title with ellipsis */}
          <h3 className="text-sm font-medium text-gray-900 line-clamp-1 pr-2" title={getDisplayTitle(conversation)}>
            {getDisplayTitle(conversation)}
          </h3>
          
          {/* Agent name and timestamp with better spacing */}
          <div className="flex items-center justify-between pr-2">
            {agentName && (
              <span className="flex items-center text-xs text-gray-500 line-clamp-1 max-w-[70%]" 
                    title={agentName}>
                <span className="w-1.5 h-1.5 bg-green-400 rounded-full mr-1.5 flex-shrink-0" />
                <span className="truncate">{agentName}</span>
              </span>
            )}
            <span 
              className="text-xs text-gray-400 flex-shrink-0" 
              title={new Date(getLastMessageTimestamp(conversation)).toLocaleString()}
            >
              {formatRelativeTime(getLastMessageTimestamp(conversation))}
            </span>
          </div>
        </div>

        {/* Delete button */}
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          className="ml-1 p-1.5 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 flex-shrink-0 self-start mt-0.5"
          aria-label="Delete conversation"
        >
          <X className="w-3.5 h-3.5" />
        </button>
      </div>
    </div>
  );
};

// Add proper interface for chat state
interface ChatState {
  currentAgentId: string | null;
  currentChatId: string | null;
  messages: ApiChatMessage[];
  chatTitle: string;
  chats: (SavedChat | DraftChat)[];
  draftChat: DraftChat | null;
}

// Add these utility functions at the top
const STORAGE_KEY_PREFIX = 'chat_history_';

// Add file validation constants
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'text/csv',
  'application/json'
];

const validateFiles = (files: FileList): { valid: File[], errors: string[] } => {
  const validFiles: File[] = [];
  const errors: string[] = [];

  Array.from(files).forEach(file => {
    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      errors.push(`${file.name} is too large (max ${formatFileSize(MAX_FILE_SIZE)})`);
      return;
    }

    // Check file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      errors.push(`${file.name} has unsupported format (${file.type})`);
      return;
    }

    validFiles.push(file);
  });

  return { valid: validFiles, errors };
};

// Add helper function to get content length
const getContentLength = (content: string | GraphContext | CommandResult): number => {
  if (typeof content === 'string') {
    return content.length;
  }
  // For non-string content, convert to string representation
  return JSON.stringify(content).length;
};

// Add this helper function
const isDraftChat = (chat: SavedChat | DraftChat): chat is DraftChat => {
  return 'isDraft' in chat && chat.isDraft;
};

const ChatInterface: React.FC = () => {
  const { selectedAgent } = useAgentContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  // Update initial state with proper typing
  const [chatState, setChatState] = useState<ChatState>({
    currentAgentId: selectedAgent?.id || null,
    currentChatId: null,
    messages: [],
    chatTitle: '',
    chats: [],
    draftChat: null
  });

  const [uiState, setUiState] = useState({
    isSidebarOpen: false,
    isProcessing: false
  });

  const [isUploading, setIsUploading] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [uploadProgress, setUploadProgress] = useState<{[key: string]: number}>({});

  // Add ref to store current chat state
  const chatStateRef = useRef({
    currentAgentId: chatState.currentAgentId,
    currentChatId: chatState.currentChatId,
    chatTitle: chatState.chatTitle,
    messages: chatState.messages,
    chats: chatState.chats
  });

  // Update ref when chat state changes
  useEffect(() => {
    chatStateRef.current = {
      currentAgentId: chatState.currentAgentId,
      currentChatId: chatState.currentChatId,
      chatTitle: chatState.chatTitle,
      messages: chatState.messages,
      chats: chatState.chats
    };
  }, [chatState]);

  // Add effect to handle agent restoration events
  useEffect(() => {
    const handleAgentConversationStarted = (event: CustomEvent<{
      agentId: string;
      conversationId: string;
      messages: ApiChatMessage[];
    }>) => {
      const { agentId, conversationId, messages } = event.detail;
      
      // Only update if this is a different agent or chat
      if (agentId !== chatState.currentAgentId || conversationId !== chatState.currentChatId) {
        setChatState(prev => ({
          ...prev,
          currentAgentId: agentId,
          currentChatId: conversationId,
          messages: messages,
          chatTitle: 'New Chat',
          chats: [
            {
              id: conversationId,
              title: 'New Chat',
              agent_id: agentId,
              messages: messages,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              isDraft: true
            },
            ...prev.chats.filter(c => c.agent_id !== agentId) // Remove old chats from this agent
          ]
        }));
      }
    };

    // Add event listener
    window.addEventListener(
      'agentConversationStarted',
      handleAgentConversationStarted as EventListener
    );

    // Cleanup
    return () => {
      window.removeEventListener(
        'agentConversationStarted',
        handleAgentConversationStarted as EventListener
      );
    };
  }, [chatState.currentAgentId, chatState.currentChatId]);

  // Update the conversation loading logic
  useEffect(() => {
    if (selectedAgent?.id) {
      const loadInitialConversations = async () => {
        try {
          setIsLoading(true);
          const conversations = await chatApi.getConversations(selectedAgent.id);
          
          // Sort conversations by last message timestamp
          const sortedConversations = [...conversations].sort((a, b) => {
            const getLastMessageTime = (chat: SavedChat) => {
              if (chat.messages && chat.messages.length > 0) {
                return new Date(chat.messages[chat.messages.length - 1].timestamp).getTime();
              }
              return new Date(chat.updated_at).getTime();
            };
            
            return getLastMessageTime(b) - getLastMessageTime(a); // Most recent first
          });
          
          if (sortedConversations.length > 0) {
            const mostRecentConversation = sortedConversations[0];
            setChatState(prev => ({
              ...prev,
              currentAgentId: selectedAgent.id,
              chats: sortedConversations,
              currentChatId: mostRecentConversation.id,
              messages: mostRecentConversation.messages,
              chatTitle: mostRecentConversation.title
            }));
          } else {
            setChatState(prev => ({
              ...prev,
              currentAgentId: selectedAgent.id,
              chats: []
            }));
          }
        } catch (error) {
          console.error('Failed to load conversations:', error);
          toast.error('Failed to load conversations');
        } finally {
          setIsLoading(false);
        }
      };

      loadInitialConversations();
    }
  }, [selectedAgent]);

  // Add scroll effect
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatState.messages]); // Scroll when messages change

  // Add dirty state tracking
  const [isDirty, setIsDirty] = useState(false);
  const previousChatState = useRef<ChatState | null>(null);

  // Create debounced save function
  const debouncedSave = useCallback(
    debounce(async (chatData: SavedChat) => {
      try {
        await saveChatData(chatData.agent_id, chatData);
        setIsDirty(false);
      } catch (error) {
        console.error('Failed to save chat:', error);
      }
    }, 1000),
    []
  );

  // Add a ref to track chat switches
  const isSwitchingChat = useRef(false);
  
  // Add cache for loaded chats
  const loadedChatsRef = useRef<Record<string, boolean>>({});
  const previousStateRef = useRef<{
    messages: typeof chatState.messages,
    chatId: string | null
  }>({
    messages: [],
    chatId: null
  });

  // Update the chat selection handler
  const handleSelectConversation = useCallback(async (conversationId: string) => {
    if (!chatState.currentAgentId || conversationId === chatState.currentChatId) return;
    
    try {
      isSwitchingChat.current = true;
      setIsLoading(true);
      
      // Check if we've already loaded this chat
      const chatData = await loadChatData(chatState.currentAgentId, conversationId);
      
      if (chatData) {
        // Only update if data actually changed
        if (!isEqual(chatData.messages, chatState.messages)) {
          setChatState(prev => ({
            ...prev,
            currentChatId: conversationId,
            messages: chatData.messages,
            chatTitle: chatData.title
          }));
        }
      } else {
        const conversation = await chatApi.getConversation(chatState.currentAgentId, conversationId);
        setChatState(prev => ({
          ...prev,
          currentChatId: conversationId,
          messages: conversation.messages,
          chatTitle: conversation.title
        }));
      }

      // Mark as loaded
      loadedChatsRef.current[conversationId] = true;

    } catch (error) {
      console.error('Failed to load conversation:', error);
      toast.error('Failed to load conversation');
    } finally {
      setIsLoading(false);
      isSwitchingChat.current = false;
    }
  }, [chatState.currentAgentId, chatState.currentChatId, chatState.messages]);

  // Update the save effect to be more precise
  useEffect(() => {
    if (!chatState.currentAgentId || !chatState.currentChatId || isSwitchingChat.current) return;

    const hasMessagesChanged = !isEqual(previousStateRef.current.messages, chatState.messages);
    const hasChatChanged = previousStateRef.current.chatId !== chatState.currentChatId;

    if (hasMessagesChanged || hasChatChanged) {
      console.log('State changed, saving...', {
        messagesChanged: hasMessagesChanged,
        chatChanged: hasChatChanged
      });

      setIsDirty(true);
      debouncedSave({
        id: chatState.currentChatId,
        title: chatState.chatTitle,
        agent_id: chatState.currentAgentId,
        messages: chatState.messages,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      });

      // Update previous state
      previousStateRef.current = {
        messages: [...chatState.messages],
        chatId: chatState.currentChatId
      };
    }
  }, [chatState.messages, chatState.currentChatId]);

  // Add cleanup for refs
  useEffect(() => {
    return () => {
      loadedChatsRef.current = {};
      previousStateRef.current = {
        messages: [],
        chatId: null
      };
    };
  }, []);

  const handleRegularMessage = async (content: string) => {
    if (!selectedAgent?.id || !chatState.currentChatId || !chatState.currentAgentId) {
      console.error('No agent or chat selected');
      return;
    }

    setIsLoading(true);
    
    try {
      const now = new Date().toISOString();
      const userMessage: ApiChatMessage = {
        id: generateUniqueId('msg'),
        role: 'user',
        content: content,
        timestamp: now
      };

      // Find current chat
      const currentChat = chatState.chats.find(c => c.id === chatState.currentChatId);
      const isFirstMessage = currentChat?.messages.length === 0;

      // Create a stable copy of the updated messages
      const updatedMessages = [...chatState.messages, userMessage];

      // Update state immediately with user message
      setChatState(prev => ({
        ...prev,
        messages: updatedMessages
      }));

      // Save the current state before API call
      await saveChatData(chatState.currentAgentId, {
        id: chatState.currentChatId,
        title: chatState.chatTitle,
        agent_id: chatState.currentAgentId,
        messages: updatedMessages,
        created_at: currentChat?.created_at || now,
        updated_at: now
      });

      // Make API request
      const response = await fetch(`${API_BASE_URL}/agent/${selectedAgent.id}/chat/completions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          messages: [{
            role: "user",
            content: content
          }],
          model: "gpt-4o",
          max_tokens: 1024,
          temperature: 0.1,
          top_p: 0.25,
          n: 1,
          stream: false
        })
      });

      const data = await response.json();
      
      // Create assistant message
      const assistantMessage: ApiChatMessage = {
        id: generateUniqueId('msg'),
        role: 'assistant',
        content: data.message?.content || data.message || 'No response content',
        timestamp: new Date().toISOString()
      };

      // Create final messages array
      const finalMessages = [...updatedMessages, assistantMessage];

      // Update state with both messages
      setChatState(prev => ({
        ...prev,
        messages: finalMessages,
        chats: prev.chats.map(c => 
          c.id === chatState.currentChatId 
            ? { ...c, messages: finalMessages }
            : c
        )
      }));

      // Save the final state
      await saveChatData(chatState.currentAgentId, {
        id: chatState.currentChatId,
        title: chatState.chatTitle,
        agent_id: chatState.currentAgentId,
        messages: finalMessages,
        created_at: currentChat?.created_at || now,
        updated_at: new Date().toISOString()
      });

    } catch (error) {
      console.error('Message handling failed:', error);
      // Restore the last known good state
      const lastKnownState = await loadChatData(chatState.currentAgentId, chatState.currentChatId);
      if (lastKnownState) {
        setChatState(prev => ({
          ...prev,
          messages: lastKnownState.messages
        }));
      }
      handleMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = useCallback(async () => {
    if (!input.trim() || isLoading || !selectedAgent?.id) return;

    try {
      setIsLoading(true);
      const newMessage: ApiChatMessage = {
        id: generateUniqueId('msg'),
        role: 'user',
        content: input,
        timestamp: new Date().toISOString()
      };

      // Get current chat
      const currentChat = chatState.chats.find(c => c.id === chatState.currentChatId);
      
      // If this is a draft chat's first message, we'll need to save it properly
      if (currentChat && isDraftChat(currentChat) && currentChat.messages.length === 0) {
        try {
          // Save the chat with its first message
          const savedChat = await chatApi.saveChat({
            ...currentChat,
            messages: [newMessage],
            title: generateTitleFromMessage(input),
            updated_at: new Date().toISOString()
          });

          // Update state with saved chat
          setChatState(prev => ({
            ...prev,
            currentChatId: savedChat.id,
            chatTitle: savedChat.title,
            messages: [newMessage],
            chats: prev.chats.map(c => 
              c.id === currentChat.id ? savedChat : c
            )
          }));
        } catch (error) {
          console.error('Failed to save draft chat:', error);
          toast.error('Failed to save chat');
          return;
        }
      } else {
        // Normal message handling for existing chats
        setChatState(prev => ({
          ...prev,
          messages: [...prev.messages, newMessage]
        }));
      }

      setInput('');

      // Rest of the message handling code...
      const response = await chatApi.sendMessage({
        messages: [...chatState.messages, newMessage],
        agent_id: selectedAgent.id
      });

      // Handle response...
      if (response.message?.content) {
        const botMessage: ApiChatMessage = {
          id: generateUniqueId('bot'),
          role: 'assistant',
          content: response.message.content,
          timestamp: new Date().toISOString()
        };

        setChatState(prev => ({
          ...prev,
          messages: [...prev.messages, botMessage]
        }));

        // Save the updated chat
        const chatToSave: SavedChat = {
          id: chatState.currentChatId!,
          title: chatState.chatTitle,
          agent_id: selectedAgent.id,
          messages: [...chatState.messages, newMessage, botMessage],
          created_at: currentChat?.created_at || new Date().toISOString(),
          updated_at: new Date().toISOString()
        };

        await chatApi.saveChat(chatToSave);
      }

    } catch (error) {
      handleMessageError(error);
    } finally {
      setIsLoading(false);
    }
  }, [input, chatState, selectedAgent, isLoading]);

  const handleNewChat = useCallback(async () => {
    if (!selectedAgent?.id) return;

    try {
      const newChat = await chatApi.startConversation(selectedAgent.id);
      
      setChatState(prev => ({
        ...prev,
        currentChatId: newChat.id,
        messages: [],
        chatTitle: 'New Chat',
        chats: [newChat, ...prev.chats],
        draftChat: newChat  // Track the current draft
      }));

    } catch (error) {
      console.error('Failed to create new chat:', error);
      toast.error('Failed to create new chat');
    }
  }, [selectedAgent]);

  // Add delete conversation handler
  const handleDeleteConversation = async (conversationId: string) => {
    try {
      setIsLoading(true);
      await chatApi.deleteConversation(chatState.currentAgentId!, conversationId);
      
      setChatState(prev => ({
        ...prev,
        chats: prev.chats.filter(c => c.id !== conversationId),
        // If we deleted the active conversation, switch to the most recent one
        ...(prev.currentChatId === conversationId && prev.chats.length > 1 && {
          currentChatId: prev.chats[0].id,
          messages: prev.chats[0].messages,
          chatTitle: prev.chats[0].title
        })
      }));

      toast.success('Conversation deleted');
    } catch (error) {
      console.error('Failed to delete conversation:', error);
      toast.error('Failed to delete conversation');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || !selectedAgent?.id) return;

    // Use Auth0 user email as user_id
    const userId = isAuthenticated && user?.email ? user.email : 'default_user';

    // Validate files first
    const { valid, errors } = validateFiles(files);
    
    if (errors.length > 0) {
      errors.forEach(error => {
        toast.error(error);
        // Add error message to chat
        const errorBotMessage: ApiChatMessage = {
          id: generateUniqueId('msg'),
          role: 'assistant',
          content: `❌ ${error}`,
          timestamp: new Date().toISOString()
        };
        setChatState(prev => ({
          ...prev,
          messages: [...prev.messages, errorBotMessage]
        }));
      });
      return;
    }

    if (valid.length === 0) {
      toast.error('No valid files to upload');
      return;
    }

    setIsUploading(true);

    try {
      await documentApi.uploadDocuments(
        selectedAgent.id,
        userId,
        valid,
        // Progress callback
        (progress) => {
          setUploadProgress(prev => ({
            ...prev,
            'overall': progress
          }));
          
          // Update for each file
          const fileProgress: Record<string, number> = {};
          valid.forEach(file => {
            fileProgress[file.name] = progress;
          });
          setUploadProgress(prev => ({
            ...prev,
            ...fileProgress
          }));
        }
      );

      // Add upload messages to chat
      for (const file of valid) {
        const fileSize = (file.size / 1024 / 1024).toFixed(2); // Convert to MB
        
        // Add user's upload action
        const userMessage: ApiChatMessage = {
          id: generateUniqueId('msg'),
          role: 'user',
          content: `📎 Uploaded document: ${file.name}`,
          timestamp: new Date().toISOString()
        };

        // Add success message
        const successMessage: ApiChatMessage = {
          id: generateUniqueId('msg'),
          role: 'assistant',
          content: `✅ I've successfully uploaded "${file.name}" (${fileSize} MB) to the document library.\n\n📚 You can view and manage this document in the Document Library section. I'll use this document's content to assist you in our conversation.`,
          timestamp: new Date().toISOString()
        };

        setChatState(prev => ({
          ...prev,
          messages: [...prev.messages, userMessage, successMessage]
        }));

        toast.success(`${file.name} uploaded successfully`);
      }

    } catch (error) {
      console.error('Error uploading file:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to upload file';
      
      // Add error message to chat
      const errorBotMessage: ApiChatMessage = {
        id: generateUniqueId('msg'),
        role: 'assistant',
        content: `❌ Sorry, I couldn't upload the file: ${errorMessage}.\n\nPlease make sure:\n- The file format is supported\n- The file size is within limits\n- You have a stable internet connection\n\nIf the problem persists, please contact support.`,
        timestamp: new Date().toISOString()
      };

      setChatState(prev => ({
        ...prev,
        messages: [...prev.messages, errorBotMessage]
      }));

      toast.error(`Failed to upload: ${errorMessage}`);
    } finally {
      setIsUploading(false);
      setUploadProgress({});
      // Clear the file input
      event.target.value = '';
    }
  };

  // Add loading indicator component
  const LoadingIndicator = () => (
    <div className="flex items-center space-x-2 p-4 bg-gray-50 rounded-lg">
      <div className="flex space-x-1">
        <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
        <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
        <div className="w-2 h-2 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
      </div>
      <span className="text-sm text-gray-600">Processing...</span>
    </div>
  );

  // Add this near other message handling code
  const handleMessageError = (error: any) => {
    console.error('Failed to process message:', error);
    
    // Check for specific error types
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      toast.error(
        <div className="flex items-center space-x-2 p-1">
          <span role="img" aria-label="error" className="text-xl">⚠️</span>
          <div>
            <p className="font-medium text-gray-900">I'm having trouble understanding that</p>
            <p className="text-sm text-gray-600">Could you rephrase your request?</p>
          </div>
        </div>,
        {
          duration: 5000,
          className: 'bg-[#E6F0FF] border border-[#CCE0FF] rounded-lg shadow-md',
          style: {
            minWidth: '320px'
          }
        }
      );
    } else if (error.message?.includes('ERR_INCOMPLETE_CHUNKED_ENCODING')) {
      toast.error(
        <div className="flex items-center space-x-2 p-1">
          <span role="img" aria-label="error" className="text-xl"></span>
          <div>
            <p className="font-medium text-gray-900">Connection interrupted</p>
            <p className="text-sm text-gray-600">Please try your request again</p>
          </div>
        </div>,
        {
          duration: 5000,
          className: 'bg-[#E6F0FF] border border-[#CCE0FF] rounded-lg shadow-md'
        }
      );
    } else {
      // Fallback to existing generic error
      toast.error('Failed to get response from agent');
    }
  };

  useEffect(() => {
    // Only run cleanup check if we have an agent selected
    if (!chatState.currentAgentId) return;

    // Load settings immediately to ensure they exist
    const settings = ensureSettings();
    
    // Check last cleanup time
    const CLEANUP_INTERVAL = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
    const lastCleanup = localStorage.getItem('lastCleanupTime');
    const now = Date.now();

    const runCleanup = async () => {
      try {
        // Run cleanup
        const cleanedChats = await cleanupConversations(chatState.currentAgentId!);
        
        // Update state if needed
        if (cleanedChats.length !== chatState.chats.length) {
          setChatState(prev => ({
            ...prev,
            chats: cleanedChats,
            // If current chat was cleaned up, switch to most recent
            ...(cleanedChats.find(c => c.id === prev.currentChatId) 
              ? {}
              : {
                  currentChatId: cleanedChats[0]?.id || null,
                  messages: cleanedChats[0]?.messages || [],
                  chatTitle: cleanedChats[0]?.title || 'New Chat'
                })
          }));
        }

        // Update last cleanup time
        localStorage.setItem('lastCleanupTime', now.toString());
        
      } catch (error) {
        console.error('Failed to run cleanup:', error);
      }
    };

    // Run cleanup if:
    // 1. No last cleanup timestamp exists, or
    // 2. It's been at least 12 hours since last cleanup
    if (!lastCleanup || (now - parseInt(lastCleanup)) > CLEANUP_INTERVAL) {
      runCleanup();
    }

    // Set up interval for future cleanups
    const cleanupInterval = setInterval(runCleanup, CLEANUP_INTERVAL);

    // Cleanup interval on unmount
    return () => clearInterval(cleanupInterval);
  }, [chatState.currentAgentId]); // Only re-run if agent changes

  // Stable link click handler using ref
  const handleLinkClick = useCallback(async (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const link = target.closest('a');
    
    if (link && !link.hasAttribute('data-internal')) {
      event.preventDefault();
      console.group('Link Click Handler');
      console.log('Link clicked:', {
        href: link.href,
        timestamp: new Date().toISOString()
      });
      
      const currentState = chatStateRef.current;
      
      if (currentState.currentAgentId && currentState.currentChatId) {
        console.log('Current chat state:', {
          agentId: currentState.currentAgentId,
          chatId: currentState.currentChatId,
          messageCount: currentState.messages.length,
          chatsCount: currentState.chats.length
        });

        try {
          const chatToSave = {
            id: currentState.currentChatId,
            title: currentState.chatTitle,
            agent_id: currentState.currentAgentId,
            messages: [...currentState.messages],
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          };

          console.log('Attempting to save chat data...');
          await saveChatData(currentState.currentAgentId, chatToSave);
          console.log('Chat data saved successfully');
          
          console.log('Saving chat metadata...');
          await saveChatMetadata(currentState.currentAgentId, currentState.chats);
          console.log('Chat metadata saved successfully');

          const savedData = localStorage.getItem(`chats:${currentState.currentAgentId}:${currentState.currentChatId}`);
          console.log('Verification - Saved data exists:', !!savedData);

          window.open(link.href, '_blank');
        } catch (error) {
          console.error('Failed to save chat state:', error);
          console.log('Error details:', {
            error: error instanceof Error ? error.message : 'Unknown error',
            chatState: {
              hasAgentId: !!currentState.currentAgentId,
              hasChatId: !!currentState.currentChatId,
              messageCount: currentState.messages.length
            }
          });
          window.open(link.href, '_blank');
        } finally {
          console.groupEnd();
        }
      } else {
        console.log('No active chat to save, opening link directly');
        window.open(link.href, '_blank');
        console.groupEnd();
      }
    }
  }, []); // Empty dependency array since we're using ref

  // Single event listener setup
  useEffect(() => {
    console.log('Setting up link click handler (once)...');
    document.addEventListener('click', handleLinkClick);
    return () => {
      console.log('Cleaning up link click handler (once)...');
      document.removeEventListener('click', handleLinkClick);
    };
  }, []); // Empty dependency array for single setup

  // Update the scroll effect to be more reliable
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      // Force immediate scroll
      messagesEndRef.current.scrollIntoView({ 
        behavior: 'auto',  // Changed from 'smooth' to 'auto' for immediate scroll
        block: 'end',
        inline: 'nearest'
      });
      
      // Then do a smooth scroll for any remaining distance
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        });
      }, 100);
    }
  };

  // Add effect to scroll on new messages with a slight delay to ensure content is rendered
  useEffect(() => {
    const scrollTimeout = setTimeout(scrollToBottom, 50);
    return () => clearTimeout(scrollTimeout);
  }, [chatState.messages]); // Scroll whenever messages change

  // Also scroll when loading state changes
  useEffect(() => {
    if (!isLoading) {
      const scrollTimeout = setTimeout(scrollToBottom, 50);
      return () => clearTimeout(scrollTimeout);
    }
  }, [isLoading]);

  // Add effect to handle chat clear events
  useEffect(() => {
    const handleChatCleared = () => {
      // Reset chat state
      setChatState(prev => ({
        ...prev,
        messages: [],
        chats: [],
        currentChatId: null,
        chatTitle: ''
      }));

      // Create new chat if agent is selected
      if (selectedAgent) {
        chatApi.startConversation(selectedAgent.id)
          .then(newChat => {
            setChatState(prev => ({
              ...prev,
              currentChatId: newChat.id,
              chatTitle: 'New Chat',
              chats: [newChat]
            }));
          })
          .catch(error => {
            console.error('Failed to create new chat after clear:', error);
            toast.error('Failed to create new chat');
          });
      }
    };

    window.addEventListener('chatHistoryCleared', handleChatCleared);
    return () => {
      window.removeEventListener('chatHistoryCleared', handleChatCleared);
    };
  }, [selectedAgent]);

  // Listen for clear events
  const handleDataCleared = () => {
    setChatState(prev => ({
      ...prev,
      chats: [],
      currentChatId: null,
      messages: [],
      chatTitle: ''
    }));
  };

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'clearChats' || e.key === 'clearAll') {
        handleDataCleared();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div className="flex h-full bg-white rounded-lg shadow-lg">
      {/* Sidebar - Updated with strict height containment */}
      <div className={`w-64 border-r border-gray-200 bg-[#F8FAFC] flex flex-col h-full overflow-hidden ${
        uiState.isSidebarOpen ? 'block' : 'hidden'
      } lg:block`}>
        {/* New Chat Button - Fixed at top with explicit height */}
        <div className="flex-shrink-0 h-[72px] p-4 border-b border-gray-200 bg-white sticky top-0 z-10 shadow-sm">
          <button
            onClick={handleNewChat}
            className="w-full flex items-center justify-center gap-2 px-4 py-2.5 text-white bg-[#004DB5] rounded-lg hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-[#004DB5] focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isLoading}
          >
            <Plus className="w-4 h-4" />
            <span className="font-medium">New Chat</span>
          </button>
        </div>

        {/* Conversations List - Calculated height container */}
        <div className="flex-1 h-[calc(100%-72px)] overflow-hidden">
          <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            {chatState.chats
              .filter((conversation, index, self) => {
                if (!conversation.id) {
                  conversation.id = generateUniqueId('conv');
                }
                return index === self.findIndex((c) => c.id === conversation.id);
              })
              .map((conversation) => (
                <ConversationListItem
                  key={conversation.id}
                  conversation={conversation}
                  isActive={conversation.id === chatState.currentChatId}
                  onSelect={() => handleSelectConversation(conversation.id)}
                  onDelete={() => handleDeleteConversation(conversation.id)}
                  agentName={selectedAgent?.name}
                />
              ))}
          </div>
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="flex-1 flex flex-col h-full">
        {/* Chat Header - Updated styling */}
        <div className="px-6 py-4 border-b bg-[#F8FAFC] shadow-sm">
          <div className="flex items-center justify-between">
            <button
              onClick={() => setUiState(prev => ({ ...prev, isSidebarOpen: !prev.isSidebarOpen }))}
              className="lg:hidden text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#004DB5] focus:ring-offset-2 rounded-md"
            >
              <Menu className="w-6 h-6" />
            </button>
            <div className="flex items-center space-x-3">
              {selectedAgent && (
                <>
                  <div className="flex items-center space-x-2">
                    <span className="w-2 h-2 rounded-full bg-green-500" />
                    <span className="text-sm font-semibold text-[#004DB5]">
                      {selectedAgent.name}
                    </span>
                  </div>
                  <span className="text-xs px-2 py-1 rounded-full bg-blue-50 text-blue-600 border border-blue-100">
                    Active
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Messages Area */}
        <div className="flex-1 overflow-y-auto px-6 py-4 space-y-4 scroll-smooth">
          {chatState.messages.map((message) => (
            <Message key={message.id} message={message} />
          ))}
          {isLoading && <LoadingIndicator />}
          <div ref={messagesEndRef} className="h-px" /> {/* Added height to ensure it's always at bottom */}
        </div>

        {/* Input Area */}
        <div className="p-4 border-t bg-white">
          <div className="flex items-center space-x-2">
            <label 
              htmlFor="file-upload" 
              className="cursor-pointer relative group"
            >
              <div className={`p-2 rounded-lg transition-colors ${
                isUploading ? 'text-gray-400' : 'text-[#004DB5] hover:bg-blue-50'
              }`}>
                <Paperclip className="w-5 h-5" />
              </div>
              {/* Tooltip */}
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none">
                <div className="relative">
                  Supported files: PDF, Word, TXT, CSV, JSON (max {formatFileSize(MAX_FILE_SIZE)})
                  <div className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 translate-y-full w-2 h-2 bg-gray-900 rotate-45"></div>
                </div>
              </div>
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                disabled={isUploading || isLoading}
                accept={ALLOWED_FILE_TYPES.join(',')}
                multiple
              />
            </label>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSend();
                }
              }}
              placeholder={isLoading ? "Processing..." : "Type a message..."}
              className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={isLoading || isUploading}
            />
            <button
              onClick={handleSend}
              disabled={isLoading || isUploading || !input.trim()}
              className={`p-2 rounded-lg transition-colors ${
                isLoading || isUploading || !input.trim()
                  ? 'bg-gray-100 text-gray-400'
                  : 'bg-[#004DB5] text-white hover:bg-blue-700'
              }`}
            >
              {isLoading ? (
                <div className="w-5 h-5 border-2 border-gray-300 border-t-gray-600 rounded-full animate-spin" />
              ) : (
                <Send className="w-5 h-5" />
              )}
            </button>
          </div>
          {isUploading && Object.keys(uploadProgress).length > 0 && (
            <div className="mt-2">
              {Object.entries(uploadProgress).map(([fileName, progress]) => (
                fileName !== 'overall' && (
                  <div key={fileName} className="mb-2">
                    <div className="flex justify-between text-xs text-gray-500 mb-1">
                      <span>{fileName}</span>
                      <span>{progress}%</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-1">
                      <div
                        className="bg-[#004DB5] h-1 rounded-full transition-all duration-300"
                        style={{ width: `${progress}%` }}
                      ></div>
                    </div>
                  </div>
                )
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
