import React, { useEffect, useState, useRef } from 'react';
import { ChevronDown } from 'lucide-react';
import { useAgentContext } from '../../context/AgentContext';
import { chatApi } from '../../services/api/chatApi';
import toast from 'react-hot-toast';
import { STORAGE_KEYS } from '../../context/AgentContext';

interface AgentPersonaSelectorProps {
  isOpen: boolean;
  onToggle: () => void;
}

const AgentPersonaSelector: React.FC<AgentPersonaSelectorProps> = ({ isOpen, onToggle }) => {
  const [isRestoring, setIsRestoring] = useState(false);
  const { liveAgents, selectedAgent, setSelectedAgent } = useAgentContext();
  
  // Add cache for loaded conversations
  const loadedConversationsRef = useRef<Record<string, boolean>>({});
  
  useEffect(() => {
    const restoreAgent = async () => {
      if (!selectedAgent || isRestoring) return;
      
      // Check if we've already loaded conversations for this agent
      if (loadedConversationsRef.current[selectedAgent.id]) {
        console.log('Conversations already loaded for agent:', selectedAgent.id);
        return;
      }
      
      setIsRestoring(true);
      try {
        const isAgentActive = liveAgents.some(
          agent => agent.id === selectedAgent.id && agent.status === 'active'
        );

        if (isAgentActive) {
          console.log('Checking existing conversations for agent:', selectedAgent.id);
          
          // Check for existing conversations in localStorage
          const existingChats = await chatApi.getConversations(selectedAgent.id);
          const hasExistingChats = existingChats && existingChats.length > 0;
          
          if (hasExistingChats) {
            console.log('Found existing chats, restoring last conversation');
            const lastChat = existingChats[0]; // Most recent chat
            
            // Mark as loaded before dispatching event
            loadedConversationsRef.current[selectedAgent.id] = true;
            
            const event = new CustomEvent('agentConversationStarted', { 
              detail: { 
                agentId: selectedAgent.id,
                conversationId: lastChat.id,
                messages: lastChat.messages || [],
                isRestored: true // Add flag to indicate this is a restoration
              }
            });
            window.dispatchEvent(event);
          } else {
            console.log('No existing chats found, starting new conversation');
            const conversation = await chatApi.startConversation(selectedAgent.id);
            
            // Mark as loaded for new conversations too
            loadedConversationsRef.current[selectedAgent.id] = true;
            
            const event = new CustomEvent('agentConversationStarted', { 
              detail: { 
                agentId: selectedAgent.id,
                conversationId: conversation.id,
                messages: conversation.messages || [],
                isRestored: false
              }
            });
            window.dispatchEvent(event);
          }
        }
      } catch (error) {
        console.error('Failed to restore agent conversation:', error);
        // Clear loaded state on error
        delete loadedConversationsRef.current[selectedAgent.id];
      } finally {
        setIsRestoring(false);
      }
    };

    restoreAgent();
  }, [selectedAgent, liveAgents]);

  // Clear loaded state when component unmounts
  useEffect(() => {
    return () => {
      loadedConversationsRef.current = {};
    };
  }, []);

  const handleAgentSelect = async (agentId: string) => {
    console.group('Agent Selection - Nav');
    console.log('Attempting to select agent:', {
      agentId,
      timestamp: new Date().toISOString()
    });

    try {
      const newAgent = liveAgents.find(agent => agent.id === agentId);
      
      if (newAgent) {
        const isVisibilityRestore = document.visibilityState === 'visible' && 
          localStorage.getItem(STORAGE_KEYS.selectedAgent) === JSON.stringify(newAgent);

        if (isVisibilityRestore) {
          console.log('Skipping restoration - just visibility change');
          return;
        }

        setSelectedAgent(newAgent);
        console.log('Agent selection updated successfully');

        if (!isVisibilityRestore) {
          console.log('Starting new conversation with agent:', agentId);
          const conversation = await chatApi.startConversation(agentId);
          console.log('Conversation started:', conversation);

          const event = new CustomEvent('agentConversationStarted', { 
            detail: { 
              agentId,
              conversationId: conversation.id,
              messages: conversation.messages || []
            }
          });
          window.dispatchEvent(event);
        }
      } else {
        console.warn('Agent not found in live agents:', {
          searchId: agentId,
          availableAgents: liveAgents
        });
        toast.error('Failed to select agent');
      }
    } catch (error) {
      console.error('Failed to initialize agent conversation:', error);
      toast.error('Failed to start conversation with agent');
    } finally {
      onToggle();
      console.groupEnd();
    }
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={onToggle}
        className="flex items-center justify-between w-64 px-4 py-2.5 text-sm bg-white border border-gray-200 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#004DB5] focus:ring-offset-2 transition-colors"
      >
        {selectedAgent ? (
          <div className="flex items-center min-w-0 flex-1">
            <span className="w-2.5 h-2.5 rounded-full bg-green-500 flex-shrink-0" />
            <span className="ml-2 font-medium text-gray-900 truncate" title={selectedAgent.name}>
              {selectedAgent.name}
            </span>
          </div>
        ) : (
          <span className="text-gray-500">Choose an Agent</span>
        )}
        <ChevronDown className={`w-4 h-4 text-gray-400 transition-transform duration-200 ml-2 flex-shrink-0 ${
          isOpen ? 'transform rotate-180' : ''
        }`} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 border border-gray-100 z-50">
          <div className="max-h-[400px] overflow-y-auto">
            <div className="px-4 py-2.5 border-b border-gray-100 flex items-center justify-between">
              <h3 className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                Available Agents
              </h3>
              <span className="text-xs font-medium text-blue-600 bg-blue-50 px-2 py-0.5 rounded-full">
                {liveAgents.length}
              </span>
            </div>
            
            <div className="py-1">
              {[...liveAgents]
                .sort((a, b) => {
                  if (!a.name && !b.name) return 0;
                  if (!a.name) return 1;
                  if (!b.name) return -1;
                  return a.name.localeCompare(b.name);
                })
                .map((agent) => (
                  <button
                    key={agent.id}
                    onClick={() => handleAgentSelect(agent.id)}
                    className={`w-full text-left px-4 py-3 hover:bg-gray-50 transition-colors group ${
                      selectedAgent?.id === agent.id ? 'bg-blue-50' : ''
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex-1 min-w-0">
                        <div className="flex items-center">
                          <span className="w-2.5 h-2.5 rounded-full bg-green-500 flex-shrink-0" />
                          <span 
                            className={`ml-2 font-medium truncate group-hover:text-blue-600 ${
                              selectedAgent?.id === agent.id ? 'text-blue-600' : 'text-gray-900'
                            }`}
                            title={agent.name}
                          >
                            {agent.name}
                          </span>
                        </div>
                        {agent.description && (
                          <p className="mt-0.5 text-xs text-gray-500 truncate pl-4.5" title={agent.description}>
                            {agent.description}
                          </p>
                        )}
                      </div>
                      {selectedAgent?.id === agent.id && (
                        <span className="ml-3 text-blue-600 flex-shrink-0">•</span>
                      )}
                    </div>
                  </button>
                ))}

              {liveAgents.length === 0 && (
                <div className="px-4 py-6 text-center">
                  <div className="text-sm font-medium text-gray-500">
                    No Active Agents
                  </div>
                  <p className="mt-1 text-xs text-gray-400">
                    Please wait for agents to become available
                  </p>
                </div>
              )}
            </div>

            {selectedAgent && (
              <>
                <div className="border-t border-gray-100" />
                <button
                  onClick={() => {
                    setSelectedAgent(null);
                    onToggle();
                  }}
                  className="w-full px-4 py-3 text-left hover:bg-gray-50 text-gray-600 hover:text-gray-900 transition-colors text-sm"
                >
                  Reset Agent Selection
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentPersonaSelector;
