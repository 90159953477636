import axios from 'axios';
import { Document, DocumentSearchResponse, DocumentUploadResponse, DocumentUpdateResponse, DocumentDeleteResponse, DocumentChunk, DocumentWithChunks, DocumentRetrieveResponse, DocumentRetrieveRequest, DocumentRetrieveResponseExtended, DocumentWithChunksExtended, DocumentChunkExtended } from '../types/document';

// Update to use the test environment URL for now
export const API_BASE_URL = 'https://document-ingestion-test.brainchain.cloud';

// Add CORS headers to axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

export const documentApi = {
  async searchDocuments(agentId: string, query: string = ''): Promise<DocumentSearchResponse> {
    try {
      const requestBody = {
        agent_id: agentId,
        query: query || '',  // Empty string if no query
        limit: 100
      };
      
      console.log('Search request body:', requestBody);
      
      const response = await api.post('/api/v1/knowledge/search', requestBody);
      return response.data;
    } catch (error: any) {
      console.log('Search error:', error);
      console.log('Search error response:', error.response?.data);
      throw error;
    }
  },

  async retrieveDocuments(
    agentId: string, 
    userId: string = 'default_user',
    options: Partial<DocumentRetrieveRequest> = {}
  ): Promise<DocumentRetrieveResponseExtended> {
    // Declare params outside try block so it's accessible in catch
    const params = {
      user_id: userId,
      limit: options.limit || 10
    };

    try {
      console.log('Document retrieve params:', params);
      
      const response = await api.post('/api/v1/knowledge/documents/retrieve', null, {
        params: params // Add parameters as query params
      });
      
      console.log('Document retrieve response:', response.data);
      
      // Transform the response to ensure backward compatibility
      const transformedDocuments = response.data.documents.map((doc: DocumentWithChunksExtended) => ({
        ...doc,
        chunks: doc.chunks.map(chunk => ({
          ...chunk,
          doc_id: chunk.doc_id || doc.doc_id,
          id: chunk.id || `chunk_${Math.random().toString(36).substr(2, 9)}`,
          idx: chunk.idx || 0,
          content: chunk.content || '',
          created_at: chunk.created_at || new Date().toISOString(),
          updated_at: chunk.updated_at || new Date().toISOString(),
          version: chunk.version || 1
        }))
      }));

      return {
        ...response.data,
        documents: transformedDocuments
      };
    } catch (error: any) {
      console.log('Full error:', error);
      console.log('Error response data:', error.response?.data);
      console.log('Error response status:', error.response?.status);
      console.log('Error response headers:', error.response?.headers);
      console.log('Request params that caused error:', params);
      throw error;
    }
  },

  async uploadDocuments(
    agentId: string, 
    userId: string,
    files: File[],
    onProgress?: (progress: number) => void,
    onStreamUpdate?: (message: string) => void
  ): Promise<DocumentUploadResponse> {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    formData.append('agent_id', agentId);
    formData.append('user_id', userId);
    
    // Add document metadata including uploader info
    const metadata = {
      uploaded_by: userId,
      upload_date: new Date().toISOString(),
      source: 'web_upload'
    };
    formData.append('document_metadata', JSON.stringify(metadata));

    try {
      const response = await api.post(
        '/api/v1/knowledge/files/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));
            onProgress?.(percentCompleted);
          }
        }
      );
      
      onStreamUpdate?.('Upload completed successfully');
      return response.data;
    } catch (error: any) {
      console.error('Upload error:', error);
      console.error('Upload error response:', error.response?.data);
      throw error;
    }
  },

  async updateDocument(agentId: string, documentId: string, metadata: any): Promise<DocumentUpdateResponse> {
    const response = await api.put('/api/v1/knowledge/update', {
      id: documentId,
      agent_id: agentId,
      metadata
    });
    return response.data;
  },

  async deleteDocument(agentId: string, documentId: string): Promise<DocumentDeleteResponse> {
    const response = await api.delete('/api/v1/knowledge/delete', {
      data: {
        id: documentId,
        agent_id: agentId
      }
    });
    return response.data;
  },

  async generateVectorIndex(agentId: string): Promise<void> {
    await api.post('/api/v1/knowledge/rag/generate_vector_index', {
      agent_id: agentId,
      index_name: "agent_documents",
      node_label: "Document",
      embedding_property: "embedding",
      vector_dimensions: 768
    });
  },

  async reindexDocumentChunks(agentId: string): Promise<void> {
    await api.post('/api/v1/knowledge/reindex_document_chunks', {
      agent_id: agentId,
      batch_size: 100
    });
  },

  async retryDocument(agentId: string, documentId: string): Promise<DocumentUpdateResponse> {
    const response = await api.post('/api/v1/knowledge/documents/retry', {
      agent_id: agentId,
      doc_id: documentId
    });
    return response.data;
  }
}; 