import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { apiClient } from '../components/agent/AgentConfigurationInterface';

export interface Node {
  id: string;
  name: string;
  group: 'agent' | 'domain' | 'user-added' | 'predefined';
  val: number;
}

export interface AgentPersona {
  id: string;
  name: string;
  description: string;
  nodes: Node[];
  knowledgeDomains: string[];
  totalNodes: number;
  recentUpdates: number;
  customDocuments: number;
}

// Update Agent interface to be more specific
export interface LiveAgent {
  id: string;
  name: string;
  status: 'active' | 'inactive';
  description?: string;
  created_at?: string | null;
  updated_at?: string | null;
}

// Procurement persona
const procurementPersona: AgentPersona = {
  id: 'procurement_analyst_001',
  name: 'Procurement Analyst',
  description: 'Specialized in supply chain and procurement',
  nodes: [],
  knowledgeDomains: ['Supply Chain', 'Vendor Management'],
  totalNodes: 1500,
  recentUpdates: 75,
  customDocuments: 3,
};

// LLM Expert persona
const llmExpertPersona: AgentPersona = {
  id: 'llm_expert_001',
  name: 'LLM Expert',
  description: 'Specialized in large language models and AI',
  nodes: [],
  knowledgeDomains: ['Natural Language Processing', 'Machine Learning'],
  totalNodes: 2000,
  recentUpdates: 100,
  customDocuments: 5,
};

// Hedge Fund Research Analyst persona
const hedgeFundAnalystPersona: AgentPersona = {
  id: 'hedge_fund_research_analyst_long_short_event_driven_quantamental_USA_stat_arb_001',
  name: 'Hedge Fund Research Analyst',
  description: 'Specialized in long/short, event-driven, quantamental, and statistical arbitrage strategies',
  nodes: [],
  knowledgeDomains: ['Financial Markets', 'Quantitative Analysis', 'Event-Driven Strategies'],
  totalNodes: 2500,
  recentUpdates: 150,
  customDocuments: 10,
};

// Licensed Custom Broker persona
const customBrokerPersona: AgentPersona = {
  id: 'licensed_custom_broker_agent_that_passed_the_test_with_a_perfect_score_001',
  name: 'Licensed Custom Broker',
  description: 'Expert in customs regulations and international trade',
  nodes: [],
  knowledgeDomains: ['Customs Regulations', 'International Trade'],
  totalNodes: 1800,
  recentUpdates: 90,
  customDocuments: 7,
};

// Macroeconomic Expert persona
const macroeconomicExpertPersona: AgentPersona = {
  id: 'macroeconomic_expert_001',
  name: 'Macroeconomic Expert',
  description: 'Specialized in global economic trends and analysis',
  nodes: [],
  knowledgeDomains: ['Global Economics', 'Monetary Policy', 'Economic Forecasting'],
  totalNodes: 2200,
  recentUpdates: 120,
  customDocuments: 8,
};

// Export personas for usage
export const personas = [
  procurementPersona,
  llmExpertPersona,
  hedgeFundAnalystPersona,
  customBrokerPersona,
  macroeconomicExpertPersona
];

// Add these constants at the top
export const STORAGE_KEYS = {
  selectedAgent: 'autobot_selected_agent',
  lastUpdated: 'autobot_agent_last_updated'
} as const;

interface AgentContextType {
  selectedPersona: AgentPersona | null;
  setSelectedPersona: React.Dispatch<React.SetStateAction<AgentPersona | null>>;
  personas: AgentPersona[];
  
  liveAgents: LiveAgent[];
  selectedAgent: LiveAgent | null;
  setSelectedAgent: React.Dispatch<React.SetStateAction<LiveAgent | null>>;
  refreshLiveAgents: () => Promise<void>;
}

const AgentContext = createContext<AgentContextType | undefined>(undefined);

export const AgentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedPersona, setSelectedPersona] = useState<AgentPersona | null>(null);
  const [liveAgents, setLiveAgents] = useState<LiveAgent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<LiveAgent | null>(() => {
    try {
      const savedAgent = localStorage.getItem(STORAGE_KEYS.selectedAgent);
      if (!savedAgent) return null;
      
      const parsed = JSON.parse(savedAgent);
      // Validate the stored data has required fields
      if (parsed && parsed.id && parsed.name) {
        return parsed;
      }
      return null;
    } catch (error) {
      console.error('Failed to load saved agent:', error);
      return null;
    }
  });
  
  // Add a new effect to handle saving agent selection
  useEffect(() => {
    if (selectedAgent) {
      try {
        localStorage.setItem(STORAGE_KEYS.selectedAgent, JSON.stringify(selectedAgent));
        localStorage.setItem(STORAGE_KEYS.lastUpdated, new Date().toISOString());
      } catch (error) {
        console.error('Failed to save agent selection:', error);
      }
    } else {
      // Clear storage when agent is deselected
      localStorage.removeItem(STORAGE_KEYS.selectedAgent);
      localStorage.removeItem(STORAGE_KEYS.lastUpdated);
    }
  }, [selectedAgent]);

  const refreshLiveAgents = async () => {
    try {
      const agents = await apiClient.listAgents();
      const activeAgents = agents.filter((agent: LiveAgent) => 
        agent && agent.id && agent.status === 'active'
      );
      
      setLiveAgents(prev => {
        const hasChanged = JSON.stringify(prev) !== JSON.stringify(activeAgents);
        if (hasChanged) {
          // Validate that selected agent is still active
          if (selectedAgent) {
            const agentStillActive = activeAgents.some(
              (agent: LiveAgent) => agent.id === selectedAgent.id
            );
            if (!agentStillActive) {
              setSelectedAgent(null);
              localStorage.removeItem(STORAGE_KEYS.selectedAgent);
              localStorage.removeItem(STORAGE_KEYS.lastUpdated);
            }
          }
          return activeAgents;
        }
        return prev;
      });
    } catch (error) {
      console.error('Failed to fetch live agents:', error);
    }
  };

  // Initial fetch of live agents
  useEffect(() => {
    refreshLiveAgents();
    
    // Set up polling interval - 2 hours in milliseconds
    const TWO_HOURS = 2 * 60 * 60 * 1000; // 7,200,000 ms
    const interval = setInterval(refreshLiveAgents, TWO_HOURS);
    
    // Add event listener for visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refreshLiveAgents();
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Update the visibility change handler with detailed logging
  useEffect(() => {
    let isNavigating = false;
    let lastAgentId = selectedAgent?.id;
    
    console.log('Setting up visibility handlers. Initial state:', {
      selectedAgentId: selectedAgent?.id,
      lastAgentId,
      timestamp: new Date().toISOString()
    });

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      console.group('BeforeUnload Event');
      console.log('Navigation starting:', {
        selectedAgentId: selectedAgent?.id,
        lastAgentId,
        timestamp: new Date().toISOString()
      });
      
      isNavigating = true;
      
      if (selectedAgent) {
        console.log('Saving agent state before unload');
        localStorage.setItem(STORAGE_KEYS.selectedAgent, JSON.stringify(selectedAgent));
        localStorage.setItem(STORAGE_KEYS.lastUpdated, new Date().toISOString());
      }
      console.groupEnd();
    };

    const handleVisibilityChange = () => {
      console.group('Visibility Change Event');
      console.log('Visibility state changed:', {
        visibilityState: document.visibilityState,
        isNavigating,
        selectedAgentId: selectedAgent?.id,
        lastAgentId,
        timestamp: new Date().toISOString()
      });

      if (document.visibilityState === 'visible' && isNavigating) {
        const savedAgent = localStorage.getItem(STORAGE_KEYS.selectedAgent);
        console.log('Checking saved agent:', {
          hasSavedAgent: !!savedAgent,
          currentSelectedAgent: selectedAgent?.id,
          lastKnownAgentId: lastAgentId
        });

        if (savedAgent) {
          const parsedAgent = JSON.parse(savedAgent);
          console.log('Parsed saved agent:', {
            parsedAgentId: parsedAgent.id,
            currentAgentId: selectedAgent?.id,
            lastAgentId,
            wouldRestore: !selectedAgent || parsedAgent.id !== lastAgentId
          });

          if (!selectedAgent || parsedAgent.id !== lastAgentId) {
            console.log('Restoring agent:', {
              from: lastAgentId,
              to: parsedAgent.id,
              reason: !selectedAgent ? 'No current agent' : 'Agent changed'
            });
            setSelectedAgent(parsedAgent);
            lastAgentId = parsedAgent.id;
          } else {
            console.log('Skipping agent restore - no change needed');
          }
        }
        isNavigating = false;
      }
      console.groupEnd();
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      console.log('Cleaning up visibility handlers:', {
        selectedAgentId: selectedAgent?.id,
        lastAgentId,
        timestamp: new Date().toISOString()
      });
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [selectedAgent, setSelectedAgent]);

  return (
    <AgentContext.Provider value={{ 
      selectedPersona, 
      setSelectedPersona, 
      personas,
      liveAgents,
      selectedAgent,
      setSelectedAgent,
      refreshLiveAgents
    }}>
      {children}
    </AgentContext.Provider>
  );
};

export const useAgentContext = () => {
  const context = useContext(AgentContext);
  if (context === undefined) {
    throw new Error('useAgentContext must be used within an AgentProvider');
  }
  return context;
};
